<template>
    <div class="container">
        <div class="columns is-multiline">
            <template v-if="this.selected_incentive != null">
                <div class="notification is-info is-rounded">
                    <i class="fas fa-gift mr-2"></i> 
                    <span>Receive <strong>{{ this.selected_incentive.offer }}</strong> with every successful referral!</span>
                </div>
            </template>
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-search mr-2"></i> Find Yourself</h1>
                <strong>Search and select your name from the dropdown then select add referral:</strong>
            </div>
            <div class="column is-12">
                <div class="field">
                    <label class="label"> <i class="fa-solid fa-users"></i> Existing Customers</label>
                    <div class="control">
                        <v-select
                            :options="clients"
                            label="name"
                            class="rounded-select"
                            placeholder="Type Name of Customer to Search"
                            v-model="existingCustomer"
                            :reduce="existingCustomer => existingCustomer.id"
                            :dropdown-should-open="dropdownShouldOpen"
                        ></v-select>
                    </div>
                </div>
                <div class="field">
                    <router-link :to="`/referrals/referral/${ref_code}/${existingCustomer}`" class="button is-primary is-rounded mt-6">
                        <i class="fas fa-plus-circle mr-2"></i> Add Referral
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: 'AddClientWithReferral',
    data() {
        return {
            name: '',
            contact_person: '',
            email: '',
            phone: '',
            estimated_value: 0,
            website: '',
            addReferral: false,
            existingCustomer: null,
            ref_code: this.$route.params.ref_code,
            team: {},
            clients: [],
            selected_incentive: null
        }
    },
    mounted() {
        this.getTeam()
        this.getClients()
    },
    components: {
        vSelect
    },
    methods: {
        dropdownShouldOpen(VueSelect) {
            if (this.existingCustomer !== null) {
                return VueSelect.open
            }
            return VueSelect.search.length !== 0 && VueSelect.open
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)
            
            const ref_code_dict = {
                ref_code: this.$route.params.ref_code
            }

            await axios
                .post('/api/v1/teams/get_my_team_with_referral/', ref_code_dict)
                .then(response => {
                    this.team = response.data
                    this.ref_code = this.$route.params.ref_code
                    this.selected_incentive = response.data.incentives.find(incentive => incentive.is_active)
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async getClients() {
            this.$store.commit('setIsLoading', true)
            
            const ref_code_dict = {
                ref_code: this.$route.params.ref_code
            }

            await axios
                .post('/api/v1/clients/current_clients/', ref_code_dict)
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
        
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    padding: 10px 15px;
    text-align: center;
    margin-bottom: 20px;
}

.notification i {
    flex-shrink: 0; /* Prevent the icon from shrinking */
}

.notification span {
    flex: 1;
    text-align: left; /* Align text to the left for better readability */
}

.button.is-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
}

.mr-2 {
    margin-right: 10px;
}
.rounded-select .vs__dropdown-toggle, 
.rounded-select .vs__dropdown-menu {
  border-radius: 15px;
}

.rounded-select .vs__selected-options {
  border-radius: 15px;
}

.rounded-select .vs__search {
  border-radius: 15px;
}

.rounded-select .vs__open-indicator {
  border-radius: 15px;
}
</style>
