<template>
    <div class="container">
      <div class="columns is-multiline">
        <template v-if="selected_incentive">
          <div class="notification is-info is-rounded">
            <i class="fas fa-gift mr-2"></i>
            <span>Receive <strong>{{ selected_incentive.offer }}</strong> with every successful referral!</span>
          </div>
        </template>
  
        <div class="column is-12">
          <h1 class="title"><i class="fas fa-user-plus mr-2"></i> Add Referral</h1>
        </div>
  
        <div class="column is-12">
          <form @submit.prevent="submitForm">
            <div class="field">
              <label class="label"><i class="fa-solid fa-user-tie"></i> Who helped you today? (optional)</label>
              <div class="control">
                <v-select
                  :options="members"
                  label="first_name"
                  placeholder="Select a team member"
                  class="rounded-select white-background"
                  v-model="selected_ref_code"
                  :reduce="selectedRefCode => selectedRefCode && selectedRefCode.profile ? selectedRefCode.profile.code : ''"
                  :dropdown-should-open="dropdownShouldOpen"
                  
                >
                  <template #option="{ first_name, last_name }">
                    <h3 style="margin: 0">{{ first_name }} {{ last_name }}</h3>
                  </template>
  
                  <template #selected-option="{ first_name, last_name}">
                    <h3 style="margin: 0">{{ first_name }} {{ last_name }}</h3>
                  </template>
                </v-select>
              </div>
            </div>
  
            <div class="field">
              <label class="label"><i class="fa-regular fa-user"></i> Name</label>
              <div class="control">
                <input type="text" class="input is-rounded" v-model="company" required>
              </div>
            </div>
  
            <div class="field">
              <label class="label"><i class="fa-solid fa-envelope"></i> Email</label>
              <div class="control">
                <input type="email" class="input is-rounded" v-model="email" required>
              </div>
            </div>
  
            <div class="field">
              <label class="label"><i class="fa-solid fa-mobile"></i> Phone</label>
              <div class="control">
                <input type="text" class="input is-rounded" v-model="phone" required>
              </div>
            </div>
  
            <div class="field">
              <label class="label"><i class="fa-solid fa-bell-concierge"></i> Desired Service or Additional Information?</label>
              <div class="control">
                <input type="text" class="input is-rounded" v-model="website">
              </div>
            </div>
  
            <div class="field">
              <div class="control">
                <button class="button is-primary is-rounded"><i class="fas fa-paper-plane mr-2"></i> Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import vSelect from 'vue-select'
  
  export default {
    name: 'AddLead',
    data() {
      return {
        company: '',
        contact_person: '',
        email: '',
        phone: '',
        estimated_value: 0,
        confidence: 0,
        website: '',
        status: 'new',
        priority: 'medium',
        selected_incentive: {},
        selected_ref_code: null,
        team: {},
        ref_code: '',
        members: [],
      }
    },
    mounted() {
      this.getTeam()
    },
    components: {
      vSelect
    },
    methods: {
      async getTeam() {
        this.$store.commit('setIsLoading', true)
  
        const ref_code_dict = {
          ref_code: this.$route.params.ref_code
        }
  
        await axios
          .post('/api/v1/teams/get_my_team_with_referral/', ref_code_dict)
          .then(response => {
            this.team = response.data
            this.members = response.data.members
            this.ref_code = this.$route.params.ref_code
            this.selected_incentive = response.data.incentives.find(incentive => incentive.is_active)
          })
          .catch(error => {
            console.log(error)
          })
  
        this.$store.commit('setIsLoading', false)
      },
      async submitForm() {
        this.$store.commit('setIsLoading', true)
  
        if (this.selected_ref_code != null & this.selected_ref_code != "") {
          this.ref_code = this.selected_ref_code
        }
  
        const lead = {
          company: this.company,
          contact_person: this.contact_person,
          email: this.email,
          phone: this.phone,
          website: this.website,
          estimated_value: this.estimated_value,
          confidence: this.confidence,
          status: this.status,
          priority: this.priority,
          ref_code: this.ref_code,
          client_id: this.$route.params.id,
          incentive: this.selected_incentive.id
        }
  
        await axios
          .post('/api/v1/leads/referral/add_ref_code/', lead)
          .then(response => {
            if (response.data.message === 'The referral was added') {
              toast({
                message: 'The referral was added',
                type: 'is-primary is-rounded',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
  
              this.$router.push('/referral/Thankyou')
            } else {
              toast({
                message: response.data.message,
                type: 'is-warning',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
            }
          })
          .catch(error => {
            toast({
              message: error.response ? error.response.data.message : 'An error occurred',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          })
  
        this.$store.commit('setIsLoading', false)
      }
    }
  }
  </script>
  
  
  <style lang="scss">
  @import "~@fortawesome/fontawesome-free/css/all.min.css";
  
  .container {
    margin-top: 20px;
  }
  
  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    padding: 10px 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .notification i {
    flex-shrink: 0; /* Prevent the icon from shrinking */
  }
  
  .notification span {
    flex: 1;
    text-align: left; /* Align text to the left for better readability */
  }
  
  .button.is-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
  }
  
  .mr-2 {
    margin-right: 10px;
  }
    .rounded-select .vs__dropdown-toggle, 
    .rounded-select .vs__dropdown-menu {
    border-radius: 15px;
    background-color: white;
    }

    .rounded-select .vs__selected-options {
    border-radius: 15px;
    background-color: white;
    }

    .rounded-select .vs__search {
    border-radius: 15px;
    background-color: white;
    }

    .rounded-select .vs__open-indicator {
    border-radius: 15px;
    }

    .white-background .vs__dropdown-toggle,
    .white-background .vs__selected-options,
    .white-background .vs__search,
    .white-background .vs__open-indicator {
    background-color: white;
    }
  </style>
  
