<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-edit"></i> Edit {{ lead.company }}</h1>
            </div>

            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label><i class="fas fa-building"></i> Company</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="lead.company">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-map-marker-alt"></i> Address</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="lead.contact_person">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-envelope"></i> Email</label>
                        <div class="control">
                            <input type="email" class="input is-rounded" v-model="lead.email">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-phone"></i> Phone</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="lead.phone">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-question-circle"></i> How can we help?</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="lead.website">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-user-circle"></i> Contact Preference</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="lead.contact_preference">
                                    <option value="Email">Email</option>
                                    <option value="Phone">Phone</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-calendar-alt"></i> Last Contacted</label>
                        <div class="control">
                            <input type="datetime-local" class="input is-rounded" v-model="lead.last_contacted">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-chart-line"></i> Confidence</label>
                        <div class="control">
                            <input type="number" class="input is-rounded" v-model="lead.confidence">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-dollar-sign"></i> Estimated value</label>
                        <div class="control">
                            <input type="number" class="input is-rounded" v-model="lead.estimated_value">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-info-circle"></i> Status</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="lead.status">
                                    <option value="new">New</option>
                                    <option value="contacted">Contacted</option>
                                    <option value="inprogress">In progress</option>
                                    <option value="lost">Lost</option>
                                    <option value="won">Won</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-exclamation-triangle"></i> Priority</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="lead.priority">
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-user"></i> Assigned to</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="assigned_to">
                                    <option value="" selected>Select member</option>
                                    <option v-for="member in team.members" v-bind:key="member.id" v-bind:value="member.id">
                                        {{ member.username }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-user-friends"></i> Referred by</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="lead.reference_by">
                                    <option value="" selected>Select customer</option>
                                    <option v-for="member in clients" v-bind:key="member.id" v-bind:value="member.id">
                                        {{ member.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-primary is-rounded"><i class="fas fa-save"></i> Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'EditLead',
    data() {
        return {
            lead: {},
            team: {
                members: [],
                clients: []
            },
            assigned_to: 0,
            clients: {}
        }
    },
    mounted() {
        this.getLead()
        this.getTeam()
    },
    methods: {
        async getLead() {
            this.$store.commit('setIsLoading', true)

            const leadID = this.$route.params.id

            axios
                .get(`/api/v1/leads/${leadID}/`)
                .then(response => {
                    this.lead = response.data
                    this.assigned_to = this.lead.assigned_to.id
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const leadID = this.$route.params.id
            this.lead.assigned_to = this.assigned_to

            axios
                .patch(`/api/v1/leads/${leadID}/`, this.lead)
                .then(response => {
                    toast({
                        message: 'The referral was updated',
                        type: 'is-primary is-rounded',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push(`/dashboard/referrals/${leadID}`)
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        },
        async getClients() {
            this.$store.commit('setIsLoading', true)
            
            const ref_code_dict = {
                ref_code: this.$route.params.ref_code
            }

            await axios
                .post('/api/v1/clients/current_clients/', ref_code_dict)
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s;
}

.box:hover {
    transform: scale(1.03);
}

.button.is-primary.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-primary.is-rounded:hover {
    background-color: #1a8cc2;
}

.label {
    font-size: 1.2em;
    color: #363636;
}
</style>
