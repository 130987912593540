<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-sms mr-2"></i> Send Text Offers</h1>
                <div class="field">
                    <label class="label">Existing Customers</label>
                    <div class="control">
                        <v-select multiple
                            :options="clients"
                            label="name"
                            placeholder="Select Multiple Customers..."
                            v-model="selected_clients"
                            @option:selected="setSelectedPhone"
                            @option:deselected="setSelectedPhone"
                        ></v-select>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Existing Referrals</label>
                    <div class="control">
                        <v-select multiple
                            :options="leads"
                            label="company"
                            placeholder="Select Multiple Referrals..."
                            v-model="selected_leads"
                            @option:selected="setSelectedPhone"
                            @option:deselected="setSelectedPhone"
                        ></v-select>
                    </div>
                </div>
                <div class="box">
                    <h2 class="subtitle"><i class="fas fa-paper-plane mr-2"></i> Contact Referrals</h2>
                    <form @submit.prevent="submitForm">
                        <div>
                            <p><strong>To: </strong>{{ selected_leads_phone.join(', ') }}</p>
                        </div>
                        <div class="field">
                            <label class="label">Message</label>
                            <div id="app" class="control">
                                <QuillEditor ref="editor" theme="snow" v-model:content="content" content-type="html" toolbar="full"/>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <button class="button is-primary is-rounded">
                                    <i class="fas fa-paper-plane mr-2"></i> Send Text Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { toast } from 'bulma-toast'

export default {
    name: 'SendTextOffers',
    components: {
        QuillEditor,
        vSelect
    },
    data() {
        return {
            selected_leads: [],
            selected_clients: [],
            selected_leads_phone: [],
            content: '',
            leads: [],
            clients: [],
            team: {},
        }
    },
    mounted() {
        this.getTeam()
        this.getClients()
    },
    methods: {
        async setSelectedPhone() {
            this.selected_leads_phone = []
            for (let lead of this.selected_leads) {
                this.selected_leads_phone.push(`${lead.company}: ${lead.phone}`)
            }
            for (let client of this.selected_clients) {
                this.selected_leads_phone.push(`${client.name}: ${client.phone}`)
            }
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const data = {
                message: this.content,
                leads: this.selected_leads.concat(this.selected_clients),
            }

            await axios
                .post(`/api/v1/leads/contact_leads_text/`, data)
                .then(response => {
                    const messageType = response.data.message === 'The message was sent' ? 'is-primary' : 'is-warning';
                    toast({
                        message: response.data.message,
                        type: messageType,
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'bottom-right',
                    })
                    this.content = ''
                    this.$store.commit('setIsLoading', false)
                    window.location.reload()
                })
                .catch(error => {
                    console.log(error)
                    this.$store.commit('setIsLoading', false)
                })
        },
        async getLeads() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get(`/api/v1/leads/`)
                .then(response => {
                    this.leads = response.data.results
                })

            await axios
                .get(`/api/v1/clients/`)
                .then(response => {
                    this.clients = response.data.results
                })

            this.$store.commit('setIsLoading', false)
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data
                    // this.clients = response.data.clients
                    this.leads = response.data.leads
                })
                .catch(error => {
                    console.log(error)
                })
            this.getLeads()
            this.$store.commit('setIsLoading', false)
        },
        async getClients() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/clients/all_clients/')
                .then(response => {
                    this.clients = response.data
                    this.num_clients = response.data.count
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.field {
    margin-bottom: 20px;
}

.label {
    font-size: 1.1em;
    color: #363636;
}

.button.is-light.is-rounded {
    background-color: #f5f5f5;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-light.is-rounded:hover {
    background-color: #e2e2e2;
}

.button.is-primary.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-primary.is-rounded:hover {
    background-color: #1a8cc2;
}

.mr-2 {
    margin-right: 8px;
}
</style>
