<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title has-text-centered"><i class="fas fa-tasks mr-2"></i> Plans</h1>
            </div>

            <div class="column is-12 has-text-centered">
                <label class="switch">
                    <input type="checkbox" v-model="isAnnual" @change="updatePrices">
                    <span class="slider round"></span>
                </label>
                <span>{{ isAnnual ? 'Annual' : 'Monthly' }} Pricing</span>
            </div>

            <div class="column is-4">
                <div class="box has-background-light has-text-centered">
                    <h2 class="subtitle"><i class="fas fa-user-circle mr-2"></i> Basic</h2>
                    <h4 class="is-size-3 has-text-primary">{{ isAnnual ? '$480/year' : '$50/month' }}</h4>
                    <p v-if="isAnnual" class="has-text-grey">Equivalent to ${{ basicMonthlyEquivalent }}/month</p>

                    <p><i class="fas fa-users mr-2"></i> Unlimited Customers</p>
                    <p><i class="fas fa-exchange-alt mr-2"></i> Unlimited Referrals</p>
                    <p><i class="fas fa-user mr-2"></i> 1 User Account</p>
                    <p class="has-text-grey mt-3">
                        {{ isAnnual ? '$192/year' : '$20/month' }} per additional User Account, Max 3 User Accounts
                        <span v-if="isAnnual"> (Equivalent to ${{ basicPerSeatMonthlyEquivalent }}/month)</span>
                    </p>
                    <div class="buttons is-centered mt-3">
                        <button @click="openModal('smallteam', 2, 1)" class="button is-primary is-rounded mt-3">Subscribe</button>
                    </div>
                </div>
            </div>

            <div class="column is-4">
                <div class="box has-background-light has-text-centered">
                    <h2 class="subtitle"><i class="fas fa-briefcase mr-2"></i> Pro</h2>
                    <h4 class="is-size-3 has-text-primary">{{ isAnnual ? '$960/year' : '$100/month' }}</h4>
                    <p v-if="isAnnual" class="has-text-grey">Equivalent to ${{ proMonthlyEquivalent }}/month</p>

                    <p><i class="fas fa-users mr-2"></i> Unlimited Customers</p>
                    <p><i class="fas fa-exchange-alt mr-2"></i> Unlimited Referrals</p>
                    <p><i class="fas fa-users-cog mr-2"></i> 5 User Accounts</p>
                    <p class="has-text-grey mt-3">
                        {{ isAnnual ? '$144/year' : '$15/month' }} per additional User Account, Max 8 User Accounts
                        <span v-if="isAnnual"> (Equivalent to ${{ proPerSeatMonthlyEquivalent }}/month)</span>
                    </p>
                    <div class="buttons is-centered mt-3">
                        <button @click="openModal('bigteam', 3, 5)" class="button is-primary is-rounded mt-3">Subscribe</button>
                    </div>
                </div>
            </div>

            <div class="column is-4">
                <div class="box has-background-light has-text-centered">
                    <h2 class="subtitle"><i class="fas fa-building mr-2"></i> Teams</h2>
                    <h4 class="is-size-3 has-text-primary">{{ isAnnual ? '$1440/year' : '$150/month' }}</h4>
                    <p v-if="isAnnual" class="has-text-grey">Equivalent to ${{ teamsMonthlyEquivalent }}/month</p>

                    <p><i class="fas fa-users mr-2"></i> Unlimited Customers</p>
                    <p><i class="fas fa-exchange-alt mr-2"></i> Unlimited Referrals</p>
                    <p><i class="fas fa-users-cog mr-2"></i> 10 User Accounts</p>
                    <p class="has-text-grey mt-3">
                        {{ isAnnual ? '$96/year' : '$10/month' }} per additional User Account, Max 15 User Accounts
                        <span v-if="isAnnual"> (Equivalent to ${{ teamsPerSeatMonthlyEquivalent }}/month)</span>
                    </p>
                    <div class="buttons is-centered mt-3">
                        <button @click="openModal('enterprise', 5, 10)" class="button is-primary is-rounded mt-3">Subscribe</button>
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <button @click="openCancelConfirmationModal" class="button is-danger is-rounded is-fullwidth"><i class="fas fa-times-circle mr-2"></i> Cancel plan</button>
            </div>
        </div>

        <!-- Add Additional Members Modal -->
        <div class="modal" :class="{'is-active': isModalActive}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Add Additional User Accounts</p>
                    <button class="delete" aria-label="close" @click="closeModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Additional User Accounts</label>
                        <p class="help">This plan comes with {{this.planMembers }} User Accounts </p>
                        
                        <div class="control">
                            <input class="input" type="number" v-model="additionalMembers" :min="0" :max="maxMembers">
                        </div>
                        <p v-if="maxMembers" class="help">Max additional User Accounts: {{ maxMembers }}</p>
                        <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="validateAndSubscribe">Subscribe</button>
                    <button class="button" @click="closeModal">Cancel</button>
                </footer>
            </div>
        </div>
        <!-- End Add Additional Members Modal -->

        <!-- Cancel Confirmation Modal -->
        <div class="modal" :class="{'is-active': isCancelConfirmationModalActive}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Confirm Cancellation</p>
                    <button class="delete" aria-label="close" @click="closeCancelConfirmationModal"></button>
                </header>
                <section class="modal-card-body">
                    <p>Are you sure you want to cancel your plan?</p>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="cancelPlan">Yes, Cancel</button>
                    <button class="button" @click="closeCancelConfirmationModal">No, Keep Plan</button>
                </footer>
            </div>
        </div>
        <!-- End Cancel Confirmation Modal -->
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'Plans',
    data() {
        return {
            pub_key: '',
            stripe: null,
            additionalMembers: 0,
            isModalActive: false,
            isCancelConfirmationModalActive: false,
            selectedPlan: '',
            isAnnual: false,
            maxMembers: null,
            errorMessage: '',
            planMembers: null,

        }
    },
    computed: {
        basicMonthlyEquivalent() {
            return (480 / 12).toFixed(2)
        },
        basicPerSeatMonthlyEquivalent() {
            return (192 / 12).toFixed(2)
        },
        proMonthlyEquivalent() {
            return (960 / 12).toFixed(2)
        },
        proPerSeatMonthlyEquivalent() {
            return (144 / 12).toFixed(2)
        },
        teamsMonthlyEquivalent() {
            return (1440 / 12).toFixed(2)
        },
        teamsPerSeatMonthlyEquivalent() {
            return (96 / 12).toFixed(2)
        }
    },
    async mounted() {
        await this.getPubKey()
        this.stripe = Stripe(this.pub_key)
    },
    methods: {
        async getPubKey() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get(`/api/v1/stripe/get_stripe_pub_key/`)
                .then(response => {
                    this.pub_key = response.data.pub_key
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        openModal(plan, maxMembers,planMembers ) {
            this.selectedPlan = plan
            this.maxMembers = maxMembers
            this.isModalActive = true
            this.planMembers = planMembers
        },
        closeModal() {
            this.isModalActive = false
            this.additionalMembers = 0
            this.selectedPlan = ''
            this.maxMembers = null
            this.errorMessage = ''
        },
        openCancelConfirmationModal() {
            this.isCancelConfirmationModalActive = true
        },
        closeCancelConfirmationModal() {
            this.isCancelConfirmationModalActive = false
        },
        async cancelPlan() {
            this.$store.commit('setIsLoading', true)

            axios
                .post('/api/v1/teams/cancel_plan/')
                .then(response => {
                    this.$store.commit('setTeam', {
                        'id': response.data.id, 
                        'name': response.data.name,
                        'plan': response.data.plan.name,
                        'max_leads': response.data.plan.max_leads,
                        'max_clients': response.data.plan.max_clients,
                        'max_members': response.data.plan.max_members,
                    })

                    toast({
                        message: 'The plan was cancelled!',
                        type: 'is-primary is-rounded',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/team')
                })

            this.$store.commit('setIsLoading', false)
            this.closeCancelConfirmationModal()
        },
        validateAndSubscribe() {
            if (this.maxMembers !== null && this.additionalMembers > this.maxMembers) {
                this.errorMessage = `You cannot add more than ${this.maxMembers} additional members.`
            } else {
                this.subscribe(this.selectedPlan)
            }
        },
        async subscribe(plan) {
            this.$store.commit('setIsLoading', true)

            const data = {
                plan: plan,
                frequency: this.isAnnual ? 'annual' : 'monthly',
                additional_members: this.additionalMembers
            }

            axios
                .post('/api/v1/stripe/create_checkout_session/', data)
                .then(response => {
                    console.log(response)
                    return this.stripe.redirectToCheckout({sessionId: response.data.sessionId})
                })
                .catch(error => {
                    console.log('Error:', error)
                })

            this.closeModal()
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s;
}

.box:hover {
    transform: scale(1.03);
}

.button.is-primary.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-primary.is-rounded:hover {
    background-color: #1a8cc2;
}

.button.is-danger.is-rounded {
    background-color: #ff3860;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-danger.is-rounded:hover {
    background-color: #ff1744;
}

.has-text-centered {
    text-align: center;
}

.mr-2 {
    margin-right: 10px;
}

.modal-card {
    width: 90%;
}

@media (min-width: 768px) {
    .modal-card {
        width: 60%;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}
</style>
