<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-users"></i> {{ team.name }}</h1>
                <div  v-if="team.created_by.id === parseInt($store.state.user.id)"  class="buttons">
                    <a href="https://billing.stripe.com/p/login/5kA4kcgvmbhkbPG000" class="button is-link is-rounded">
                        <i class="fas fa-portal"></i> Customer Portal
                    </a>
                    <template v-if="team.created_by.id === parseInt($store.state.user.id)">
                                <router-link class="button is-primary is-rounded"
                                    to="/dashboard/team/add-member"
                                    v-if="this.$store.state.team.max_members > team.members.length">
                                    <i class="fas fa-user-plus"></i> Add Member
                                </router-link>
                                <div class="button is-light is-rounded" v-else>
                                    <i class="fas fa-users-slash"></i> Max Members Reached
                                </div>
                    </template>
                    <button v-if="team.created_by.id === parseInt($store.state.user.id)" class="button is-warning is-rounded" @click="toggleEditMode">
                        <i class="fas fa-edit"></i> Edit Members
                    </button>
                </div>
                <hr>
                <div class="columns">
                    <div class="column is-6">
                        <div class="box">
                            <h2 class="title"><i class="fas fa-info-circle"></i> Team Details</h2>
                            <p><strong><i class="fas fa-scroll"></i> Plan: </strong>{{ this.$store.state.team.plan }}</p>
                            <p><strong><i class="fas fa-user-friends"></i> Max Customers: </strong>Unlimited</p>
                            <p><strong><i class="fas fa-user-plus"></i> Max Referrals: </strong>Unlimited</p>
                            <p><strong><i class="fas fa-users-cog"></i> Max Members: </strong>{{ this.$store.state.team.max_members }}</p>
                            <p v-if="$store.state.team.plan !== 'Starter'"><strong><i class="fas fa-calendar-alt"></i> Next Billing Date: </strong>{{ new Date(team.plan_end_date).toDateString() }}</p>
                            <p v-if="team.created_by.id === parseInt($store.state.user.id)">
                                <router-link class="button is-info is-rounded" :to="{'name': 'Plans'}">
                                    <i class="fas fa-exchange-alt"></i> Change Plan
                                </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h2 class="title"><i class="fas fa-users"></i> Members</h2>
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Full name</th>
                                <th v-if="isEditMode"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="member in team.members" v-bind:key="member.id">
                                <td>{{ member.username }}</td>
                                <td>{{ member.first_name }} {{ member.last_name }}</td>
                                <td v-if="isEditMode && member.id !== team.created_by.id">
                                    <button class="button is-danger is-rounded is-small" @click="confirmDelete(member)">
                                        <i class="fa-regular fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Confirmation Modal -->
        <div class="modal" :class="{'is-active': showModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Confirm Delete</p>
                    <button class="delete" aria-label="close" @click="showModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <p>Are you sure you want to remove this member?</p>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="deleteMember">Delete</button>
                    <button class="button" @click="showModal = false">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>




<script>
import axios from 'axios';

export default {
    name: 'Team',
    data() {
        return {
            team: {
                members: [],
                created_by: {}
            },
            showModal: false,
            memberToDelete: null,
            isEditMode: false
        }
    },
    mounted() {
        this.getTeam();
    },
    methods: {
        async getTeam() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
            
            this.$store.commit('setIsLoading', false);
        },
        toggleEditMode() {
            this.isEditMode = !this.isEditMode;
        },
        confirmDelete(member) {
            this.memberToDelete = member;
            this.showModal = true;
        },
        async deleteMember() {
            if (this.memberToDelete) {
                const data = {
                    email: this.memberToDelete.username
                };

                await axios.post('/api/v1/teams/remove_member/', data)
                    .then(response => {
                        this.team.members = this.team.members.filter(member => member.id !== this.memberToDelete.id);
                        this.showModal = false;
                        this.memberToDelete = null;
                    })
                    .catch(error => {
                        console.log(error);
                        this.showModal = false;
                    });
            }
        }
    }
};

</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.box:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button.is-light.is-rounded,
.button.is-info.is-rounded,
.button.is-danger.is-rounded,
.button.is-primary.is-rounded {
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.button.is-light.is-rounded:hover {
    background-color: #e2e2e2;
}

.button.is-info.is-rounded:hover {
    background-color: #1a8cc2;
}

.button.is-danger.is-rounded:hover {
    background-color: #ff1744;
}

.button.is-primary.is-rounded:hover {
    background-color: #0d6efd;
}

.label {
    font-size: 1.2em;
    color: #363636;
}

.input.is-rounded,
.select.is-rounded select {
    border-radius: 20px;
}

.field:not(:last-child) {
    margin-bottom: 20px;
}

.modal-card {
    max-width: 400px;
    margin: auto;
}

.modal-card-head,
.modal-card-foot {
    justify-content: center;
}

.modal-card-body {
    text-align: center;
}

</style>
