<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fa-solid fa-people-arrows"></i> {{ lead.company }}</h1>
                <div class="buttons">
                    <button @click="goBack" class="button is-light is-rounded">
                        <i class="fas fa-arrow-left"></i> Back
                    </button>
                    <button v-if="!isEditing" @click="isEditing = true" class="button is-light is-rounded">
                        <i class="fas fa-edit"></i> Edit
                    </button>
                    <button v-else @click="saveLead" class="button is-primary is-rounded">
                        <i class="fas fa-save"></i> Save
                    </button>
                    <button v-if="isEditing" @click="cancelEdit" class="button is-light is-rounded">
                        <i class="fas fa-times"></i> Cancel
                    </button>
                    <button @click="showConvertModal = true" class="button is-info is-rounded">
                        <i class="fas fa-user-check"></i> Convert to Customer
                    </button>
                    <button @click="showDeleteModal = true" class="button is-danger is-rounded">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="title"><i class="fas fa-info-circle"></i> Details</h2>
                    <div class="field">
                        <label class="label"><i class="fas fa-user"></i> Assigned to:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.assigned_to ? lead.assigned_to.first_name + ' ' + lead.assigned_to.last_name + ' (' + lead.assigned_to.username + ')' : 'Not Assigned' }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="assigned_to">
                                    <option value="" selected>Select member</option>
                                    <option v-for="member in team.members" :key="member.id" :value="member.id">
                                        {{ member.username }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-exclamation-circle"></i> Status:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.status }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="lead.status">
                                    <option value="new">New</option>
                                    <option value="contacted">Contacted</option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="closed">Closed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fa-solid fa-money-bill-wave"></i> Referral Incentive Status:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.payment_status }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="lead.payment_status">
                                    <option value="not started">Not Started</option>
                                    <option value="complete">Complete</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fa-solid fa-money-bill"></i>Customer Incentive Status:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.customer_payment_status }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="lead.customer_payment_status">
                                    <option value="not started">Not Started</option>
                                    <option value="complete">Complete</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-user-friends"></i> Referred By:</label>
                        <div class="control">
                            <div v-if="reference_by"><router-link :to="{ name: 'Client', params: { id: reference_by.id }}">{{ reference_by.name }}</router-link></div>
                            <div v-else>Not Available</div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-star"></i> Priority:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.priority }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="lead.priority">
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-percentage"></i> Confidence:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.confidence }}</div>
                            <div v-else>
                                <input type="number" class="input is-rounded" v-model="lead.confidence">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-dollar-sign"></i> Estimated value:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.estimated_value }}</div>
                            <div v-else>
                                <input type="number" class="input is-rounded" v-model="lead.estimated_value">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-calendar-alt"></i> Referred on:</label>
                        <div class="control">
                            <div>{{ new Date(lead.created_at).toDateString() }}</div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-gift"></i> Incentive Used:</label>
                        <div class="control">
                            <div v-if="incentive">
                                <router-link :to="{ name: 'Incentive', params: { id: incentive.id }}">{{ incentive.name }}</router-link>
                            </div>
                            <div v-else>No Incentive Used</div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-calendar-alt"></i> Modified on:</label>
                        <div class="control">
                            <div>{{ new Date(lead.modified_at).toDateString() }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="title"><i class="fas fa-address-book"></i> Contact Information</h2>
                    <div class="field">
                        <label class="label"><i class="fas fa-map-marker-alt"></i> Address:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.contact_person }}</div>
                            <div v-else>
                                <input type="text" class="input is-rounded" v-model="lead.contact_person">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-envelope"></i> Email:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.email }}</div>
                            <div v-else>
                                <input type="email" class="input is-rounded" v-model="lead.email">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-phone"></i> Phone:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.phone }}</div>
                            <div v-else>
                                <input type="text" class="input is-rounded" v-model="lead.phone">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-info-circle"></i> Desired Service or Additional Information:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.website }}</div>
                            <div v-else>
                                <input type="text" class="input is-rounded" v-model="lead.website">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-hand-point-right"></i> Contact Preference:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ lead.contact_preference }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="lead.contact_preference">
                                    <option value="Email">Email</option>
                                    <option value="Phone">Phone</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-calendar-check"></i> Last Contacted:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ new Date(lead.last_contacted).toLocaleString() }}</div>
                            <div v-else>
                                <input type="datetime-local" class="input is-rounded" v-model="lead.last_contacted">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h2 class="title"><i class="fas fa-history"></i> Contact Log</h2>
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Template</th>
                                <th>Sent By</th>
                                <th>Sent At</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="contact in lead.contactlogs" :key="contact.id">
                                <td>{{ contact.template.name }}</td>
                                <td>{{ contact.created_by.username }}</td>
                                <td>{{ new Date(contact.created_at).toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h2 class="title"><i class="fas fa-paper-plane"></i> Contact Referral</h2>
                    <div class="field">
                        <label class="label">Templates</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="selected_template">
                                    <option value="" selected>Select Template</option>
                                    <option v-for="member in team.templates" :key="member.id" :value="member.id">
                                        {{ member.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <button class="button is-light is-rounded" @click="loadTemplate()"><i class="fas fa-download"></i> Load Template</button>
                        </div>
                    </div>
                    <form @submit.prevent="submitForm">
                        <div>
                            <p><strong>To: </strong>{{ lead.email }}</p>
                            <p><strong>From: </strong> hello@refrme.io </p>
                        </div>
                        <div class="field">
                            <label class="label">Subject</label>
                            <div class="control">
                                <input type="text" class="input is-rounded" v-model="subject">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Message</label>
                            <div id="app" class="control">
                                <QuillEditor ref="editor" theme="snow" v-model:content="content" content-type="html" toolbar="full" />
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <button class="button is-primary is-rounded"><i class="fas fa-paper-plane"></i> Send Email</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal" :class="{ 'is-active': showDeleteModal }">
                <div class="modal-background"></div>
                <div class="modal-content">
                    <div class="box">
                        <p>Are you sure you want to delete this referral?</p>
                        <div class="buttons">
                            <button class="button is-danger" @click="deleteLead">Yes, Delete</button>
                            <button class="button" @click="showDeleteModal = false">Cancel</button>
                        </div>
                    </div>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="showDeleteModal = false"></button>
            </div>

            <!-- Convert to Customer Confirmation Modal -->
            <div class="modal" :class="{ 'is-active': showConvertModal }">
                <div class="modal-background"></div>
                <div class="modal-content">
                    <div class="box">
                        <p>Are you sure you want to convert this referral to a customer?</p>
                        <div class="buttons">
                            <button class="button is-info" @click="convertToClient">Yes, Convert</button>
                            <button class="button" @click="showConvertModal = false">Cancel</button>
                        </div>
                    </div>
                </div>
                <button class="modal-close is-large" aria-label="close" @click="showConvertModal = false"></button>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { toast } from 'bulma-toast'

export default {
    name: 'Lead',
    components: {
        QuillEditor
    },
    data() {
        return {
            lead: {},
            content: '',
            subject: '',
            selected_template: '',
            reference_by: null,
            team: {},
            incentive: null,  // Add incentive data
            isEditing: false,
            assigned_to: null,
            showDeleteModal: false,
            showConvertModal: false
        }
    },
    mounted() {
        this.getLead();
        this.getTeam();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getLead() {
            const leadID = this.$route.params.id

            await axios
                .get(`/api/v1/leads/${leadID}/`)
                .then(response => {
                    this.lead = response.data
                    this.assigned_to = this.lead.assigned_to ? this.lead.assigned_to.id : null
                    if (this.lead.last_contacted) {
                        const date = new Date(this.lead.last_contacted);
                        this.lead.last_contacted = date.toISOString().substring(0, 16);
                    }
                    this.getClient();
                    if (this.lead.incentive) {
                        this.getIncentive(this.lead.incentive);
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getIncentive(incentiveID) {
            const data = { selected_incentive: { id: incentiveID } };

            await axios
                .post('/api/v1/teams/get_incentive/', data)
                .then(response => {
                    this.incentive = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async saveLead() {
            const leadID = this.$route.params.id
            this.lead.assigned_to = this.assigned_to
            if (this.lead.last_contacted) {
                this.lead.last_contacted = new Date(this.lead.last_contacted).toISOString()
            }

            await axios
                .patch(`/api/v1/leads/${leadID}/`, this.lead)
                .then(response => {
                    toast({
                        message: 'Lead information updated successfully',
                        type: 'is-primary is-rounded',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                    this.isEditing = false
                    this.getLead()
                })
                .catch(error => {
                    console.log(error)
                    let errorMessage = 'Failed to update Referral information. Please try again.'
                    if (error.response && error.response.data) {
                        // Extract and format error details
                        const errors = error.response.data
                        errorMessage = Object.keys(errors)
                            .map(key => `${key}: ${errors[key].join(', ')}`)
                            .join(' ')
                    }
                    toast({
                        message: errorMessage,
                        type: 'is-danger is-rounded',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                        
                })
        },
        cancelEdit() {
            this.isEditing = false
            this.getLead()
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const leadID = this.$route.params.id

            const data = {
                subject: this.subject,
                message: this.content,
                email: this.lead.email,
                lead: this.lead,
                template: this.selected_template,
                status: this.lead.status,
                reference_by: this.lead.reference_by
            }

            await axios
                .post(`/api/v1/leads/contact_lead/${leadID}/`, data)
                .then(response => {
                    if (response.data.message === 'The email was sent') {
                        toast({
                            message: 'The email was sent',
                            type: 'is-primary is-rounded',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                        this.content = ''
                        this.subject = ''
                        this.$router.push({ name: 'Lead', params: { id: this.$route.params.id } })
                    } else {
                        toast({
                            message: 'The email was not sent',
                            type: 'is-warning',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            window.location.reload()

            this.$store.commit('setIsLoading', false)
        },
        async deleteLead() {
            const leadID = this.$route.params.id

            await axios
                .post(`/api/v1/leads/delete_lead/${leadID}/`)
                .then(response => {
                    this.$router.push('/dashboard/referrals')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async convertToClient() {
            const leadID = this.$route.params.id
            const data = { lead_id: leadID }

            await axios
                .post(`/api/v1/convert_lead_to_client/`, data)
                .then(response => {
                    this.$router.push('/dashboard/customers')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getTeam() {
            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getClient() {
            await axios
                .get(`/api/v1/clients/${this.lead.reference_by}/`)
                .then(response => {
                    this.reference_by = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async loadTemplate() {
            const data = { id: this.selected_template }

            await axios
                .post('/api/v1/teams/get_template/', data)
                .then(response => {
                    this.$refs.editor.setHTML(response.data.content)
                    this.subject = response.data.subject
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>


<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.box:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button.is-light.is-rounded,
.button.is-info.is-rounded,
.button.is-danger.is-rounded,
.button.is-primary.is-rounded {
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.button.is-light.is-rounded:hover {
    background-color: #e2e2e2;
}

.button.is-info.is-rounded:hover {
    background-color: #1a8cc2;
}

.button.is-danger.is-rounded:hover {
    background-color: #ff1744;
}

.button.is-primary.is-rounded:hover {
    background-color: #0d6efd;
}

.label {
    font-size: 1.2em;
    color: #363636;
}

.input.is-rounded,
.select.is-rounded select {
    border-radius: 20px;
}

.field:not(:last-child) {
    margin-bottom: 20px;
}
</style>
