<template>
    <div class="container">
        <div class="columns is-multiline is-variable is-8">
            <div class="column is-12">
                <h1 class="title has-text-centered">{{ team.name }}'s Insights</h1>
                
                <div class="field mb-5 has-text-centered">
                    <div class="control buttons-group">
                        <button @click="setDateRange(30)" :class="{'is-active': activeRange === 30}" class="button is-medium is-info is-light rounded-button" aria-label="Show data for last 30 days">30 days</button>
                        <button @click="setDateRange(60)" :class="{'is-active': activeRange === 60}" class="button is-medium is-info is-light rounded-button" aria-label="Show data for last 60 days">60 days</button>
                        <button @click="setDateRange(90)" :class="{'is-active': activeRange === 90}" class="button is-medium is-info is-light rounded-button" aria-label="Show data for last 90 days">90 days</button>
                        <button @click="setDateRange(180)" :class="{'is-active': activeRange === 180}" class="button is-medium is-info is-light rounded-button" aria-label="Show data for last 180 days">180 days</button>
                    </div>
                </div>

                <div class="columns is-variable is-8">
                    <div class="column is-4">
                        <div class="box has-background-primary-light p-5 animated-box">
                            <p class="title has-text-primary-dark">
                                <i class="fas fa-envelope-open-text"></i> {{ numLeads }}
                            </p>
                            <p class="subtitle has-text-primary-dark">New Referrals</p>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div class="box has-background-link-light p-5 animated-box">
                            <p class="title has-text-link-dark">
                                <i class="fas fa-users"></i> {{ numClients }}
                            </p>
                            <p class="subtitle has-text-link-dark">New Customers</p>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div class="box has-background-success-light p-5 animated-box">
                            <p class="title has-text-success-dark">
                                <i class="fas fa-chart-line"></i> {{ avgReferralsPerCustomerRounded }}
                            </p>
                            <p class="subtitle has-text-success-dark">Avg Referrals per Customer</p>
                        </div>
                    </div>
                </div>

                <div class="columns is-variable is-8 mt-5">
                    <div class="column is-4">
                        <div class="box has-background-warning-light p-5 animated-box">
                            <p class="title has-text-warning-dark">
                                <i class="fas fa-percentage"></i> {{ referralConversionRate }}%
                            </p>
                            <p class="subtitle has-text-warning-dark">Referral Conversion Rate</p>
                        </div>
                    </div>
                
                    <div class="column is-4">
                        <div class="box has-background-info-light p-5 animated-box">
                            <p class="title has-text-info-dark">
                                <i class="fas fa-star"></i> Referral Champions
                            </p>
                            <div class="content has-text-info-dark">
                                <ul>
                                    <li v-for="(referrer, index) in topReferrers" :key="referrer.id" class="my-2">
                                        <span class="icon has-text-info-dark">
                                            <i :class="getMedalClass(index + 1)"></i>
                                        </span>
                                        <router-link :to="{ name: 'Client', params: { id: referrer.id } }" class="has-text-info-dark">
                                            {{ referrer.name }}
                                        </router-link>
                                        <span> - {{ referrer.count }} </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div class="box has-background-warning-light p-5 animated-box">
                            <p class="title has-text-link-dark">
                                <i class="fas fa-user-tie"></i> Referrals by Employee
                            </p>
                            <div class="content has-text-link-dark">
                                <ul>
                                    <li v-for="(employee, index) in topEmployees" :key="employee.id" class="my-2">
                                        <span class="icon has-text-link-dark">
                                            <i :class="getMedalClass(index + 1)"></i>
                                        </span>
                                        <span class="has-text-link-dark">
                                            {{ employee.first_name }} {{ employee.last_name }}
                                        </span>
                                        <span> - {{ employee.count }} </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns is-variable is-8 mt-5">
                    <div class="column is-6">
                        <div class="box has-background-info-light p-5 animated-box">
                        <p class="title has-text-info-dark">
                            <i class="fas fa-dollar-sign"></i> {{ formatCurrency(valueOpenReferrals) }}
                        </p>
                        <p class="subtitle has-text-info-dark">Value of Open Referrals</p>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="box has-background-success-light p-5 animated-box">
                        <p class="title has-text-success-dark">
                            <i class="fas fa-dollar-sign"></i> {{ formatCurrency(valueWonReferrals) }}
                        </p>
                        <p class="subtitle has-text-success-dark">Value of Closed Referrals</p>
                        </div>
                    </div>
                </div>

                <div class="live-feed-container has-background-dark mt-5">
                    <p class="title has-text-white sticky-header">
                        <i class="fas fa-bell"></i> Recent Referrals
                    </p>
                    <div class="live-feed" ref="feed">
                        <div v-for="lead in leads" :key="lead.id" class="box has-background-light mb-3 feed-item">
                            <div class="columns is-mobile is-vcentered">
                                <div class="column is-3 has-text-centered">
                                    <p class="is-size-7 has-text-grey">{{ new Date(lead.created_at).toLocaleDateString() }}</p>
                                    <p class="is-size-7 has-text-grey">{{ new Date(lead.created_at).toLocaleTimeString() }}</p>
                                </div>
                                <div class="column is-7">
                                    <p class="subtitle mb-1">
                                        <strong><i class="fa-solid fa-people-arrows"></i> {{ lead.company }}</strong>
                                    </p>
                                    <p class="is-size-7">
                                        <strong><i class="fas fa-user"></i> Assigned To:</strong> {{ lead.assigned_to ? `${lead.assigned_to.first_name} ${lead.assigned_to.last_name}` : 'Unassigned' }}
                                    </p>
                                    <p class="is-size-7">
                                        <strong><i class="fas fa-envelope"></i> Email:</strong> {{ lead.email }}
                                    </p>
                                    <p class="is-size-7">
                                        <strong><i class="fas fa-user-friends"></i> Referred By:</strong> {{ lead.referred_by }}
                                    </p>
                                </div>
                                <div class="column is-2 has-text-centered">
                                    <router-link :to="{ name: 'Lead', params: { id: lead.id }}" class="icon" aria-label="View lead details">
                                        <i class="fa-solid fa-arrow-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns is-variable is-8 mt-5">
                    <div class="column is-6">
                        <div class="box has-background-grey-dark p-5 animated-box">
                            <p class="title has-text-white">
                                <i class="fas fa-chart-pie"></i> Referral Status Breakdown
                            </p>
                            <div class="chart-container">
                                <div id="referralStatusChart" class="mini-chart" aria-label="Referral status breakdown chart"></div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="box has-background-grey-dark p-5 animated-box">
                            <p class="title has-text-white">
                                <i class="fas fa-chart-line"></i> Monthly New Referrals
                            </p>
                            <div class="chart-container">
                                <div id="monthlyReferralsChart" class="mini-chart" aria-label="Monthly new referrals chart"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import debounce from 'lodash/debounce';

export default {
    name: 'Dashboard',
    data() {
        return {
            leads: [],
            clients: [],
            numLeads: 0,
            numClients: 0,
            team: {},
            startDate: '',
            endDate: '',
            chartsRendered: false,
            activeRange: 30,
            statusFilter: '',
            assignedToFilter: '',
            searchQuery: '',
            sortField: '',
            sortOrder: 'asc',
            referralStatusChart: null,
            monthlyReferralsChart: null,
            topReferrers: [],
            topEmployees: []
        };
    },
    mounted() {
        this.setDateRange(30);
        this.fetchData();
    },
    methods: {
        async fetchData() {
            await Promise.all([this.getAllLeads(), this.getAllClients(), this.getTeam()]);
            await this.setTopReferrers();
            await this.setTopEmployees();
            await this.lookupReferredBy();
            this.renderCharts();
        },
        roundToPrecision(num, decimalPlaces) {
            const factor = Math.pow(10, decimalPlaces);
            return Math.round(num * factor) / factor;
        },
        
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async getAllLeads() {
            this.$store.commit('setIsLoading', true);
            let url = `/api/v1/leads/all_leads/?start_date=${this.startDate}&end_date=${this.endDate}`;
            if (this.statusFilter) url += `&status=${encodeURIComponent(this.statusFilter)}`;
            if (this.assignedToFilter) url += `&assigned_to=${encodeURIComponent(this.assignedToFilter)}`;
            if (this.searchQuery) url += `&search=${encodeURIComponent(this.searchQuery)}`;
            if (this.sortField) url += `&ordering=${this.sortOrder === 'asc' ? '' : '-'}${this.sortField}`;

            try {
                const response = await axios.get(url);
                this.leads = response.data;
                this.numLeads = this.leads.length;
            } catch (error) {
                console.error(error);
            }

            this.$store.commit('setIsLoading', false);
        },
        async getAllClients() {
            this.$store.commit('setIsLoading', true);
            let url = `/api/v1/clients/all_clients/?start_date=${this.startDate}&end_date=${this.endDate}`;
            if (this.statusFilter) url += `&status=${encodeURIComponent(this.statusFilter)}`;
            if (this.assignedToFilter) url += `&assigned_to=${encodeURIComponent(this.assignedToFilter)}`;
            if (this.searchQuery) url += `&search=${encodeURIComponent(this.searchQuery)}`;
            if (this.sortField) url += `&ordering=${this.sortOrder === 'asc' ? '' : '-'}${this.sortField}`;

            try {
                const response = await axios.get(url);
                this.clients = response.data;
                this.numClients = this.clients.length;
            } catch (error) {
                console.error(error);
            }

            this.$store.commit('setIsLoading', false);
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get('/api/v1/teams/get_my_team/');
                this.team = response.data;
            } catch (error) {
                console.error(error);
            }
            this.$store.commit('setIsLoading', false);
        },
        async setTopReferrers() {
            const referrerCounts = this.leads.reduce((acc, lead) => {
                const referrerId = lead.reference_by;
                if (referrerId) {
                    acc[referrerId] = acc[referrerId] || { count: 0, lastDate: new Date(0) };
                    acc[referrerId].count += 1;
                    const leadDate = new Date(lead.created_at);
                    if (leadDate > acc[referrerId].lastDate) {
                        acc[referrerId].lastDate = leadDate;
                    }
                }
                return acc;
            }, {});

            const sortedReferrers = Object.entries(referrerCounts)
                .sort((a, b) => {
                    if (b[1].count === a[1].count) {
                        return b[1].lastDate - a[1].lastDate;
                    }
                    return b[1].count - a[1].count;
                })
                .slice(0, 3);

            this.topReferrers = await Promise.all(
                sortedReferrers.map(async ([referrerId, data]) => {
                    const response = await axios.get(`/api/v1/clients/${referrerId}/`);
                    return { ...response.data, count: data.count };
                })
            );
        },
        async setTopEmployees() {
            const employeeCounts = this.leads.reduce((acc, lead) => {
                const employee = lead.assigned_to;
                if (employee) {
                    acc[employee.id] = acc[employee.id] || { count: 0, lastDate: new Date(0), ...employee };
                    acc[employee.id].count += 1;
                    const leadDate = new Date(lead.created_at);
                    if (leadDate > acc[employee.id].lastDate) {
                        acc[employee.id].lastDate = leadDate;
                    }
                }
                return acc;
            }, {});

            const sortedEmployees = Object.entries(employeeCounts)
                .sort((a, b) => {
                    if (b[1].count === a[1].count) {
                        return b[1].lastDate - a[1].lastDate;
                    }
                    return b[1].count - a[1].count;
                })
                .slice(0, 3);

            this.topEmployees = sortedEmployees.map(([id, data]) => data);
        },
        renderCharts() {
            this.destroyCharts();
            this.$nextTick(() => {
                this.renderReferralStatusChart();
                this.renderMonthlyReferralsChart();
            });
        },
        destroyCharts() {
            if (this.referralStatusChart) {
                this.referralStatusChart.dispose();
                this.referralStatusChart = null;
            }
            if (this.monthlyReferralsChart) {
                this.monthlyReferralsChart.dispose();
                this.monthlyReferralsChart = null;
            }
        },
        renderReferralStatusChart() {
            this.$nextTick(() => {
                const statusCounts = this.leads.reduce((acc, lead) => {
                    acc[lead.status] = (acc[lead.status] || 0) + 1;
                    return acc;
                }, {});

                const chartDom = document.getElementById('referralStatusChart');
                if (!chartDom) {
                    console.error("Cannot get element 'referralStatusChart'");
                    return;
                }
                this.referralStatusChart = echarts.init(chartDom);

                const option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c} ({d}%)' // Show percentage in tooltip
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                        textStyle: {
                            fontSize: 14, // Adjust font size
                            fontWeight: 'bold', // Adjust font weight
                            color: '#FFFFFF' // Adjust color to be readable on dark background
                        }
                    },
                    series: [
                        {
                            name: 'Status',
                            type: 'pie',
                            radius: ['30%', '50%'], // Inner and outer radius to give more space
                            avoidLabelOverlap: true,
                            label: {
                                show: true,
                                position: 'outside',
                                formatter: '{d}%', // Label format
                                fontSize: 14, // Adjust font size
                                fontWeight: 'bold', // Adjust font weight
                                color: '#FFFFFF' // Adjust color to be readable on dark background
                            },
                            labelLine: {
                                show: true,
                                length: 2,
                                length2: 2 // Control the length of label lines to avoid cut-offs
                            },
                            data: Object.keys(statusCounts).map(status => ({
                                value: statusCounts[status],
                                name: status
                            })),
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };

                this.referralStatusChart.setOption(option);
            });
        },

        renderMonthlyReferralsChart() {
            this.$nextTick(() => {
                const monthlyCounts = this.leads.reduce((acc, lead) => {
                    const month = new Date(lead.created_at).getMonth();
                    acc[month] = (acc[month] || 0) + 1;
                    return acc;
                }, {});

                const chartDom = document.getElementById('monthlyReferralsChart');
                if (!chartDom) {
                    console.error("Cannot get element 'monthlyReferralsChart'");
                    return;
                }
                this.monthlyReferralsChart = echarts.init(chartDom);

                const option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        data: Object.keys(monthlyCounts).map(month => new Date(0, month).toLocaleString('en-US', { month: 'short' })),
                        axisLabel: {
                            fontSize: 14, // Adjust font size
                            fontWeight: 'bold', // Adjust font weight
                            color: '#FFFFFF' // Adjust color to be readable on dark background
                        }
                    },
                    yAxis: {
                        type: 'value',
                        minInterval: 1,
                        axisLabel: {
                            fontSize: 14, // Adjust font size
                            fontWeight: 'bold', // Adjust font weight
                            color: '#FFFFFF' // Adjust color to be readable on dark background
                        }
                    },
                    series: [
                        {
                            name: 'New Referrals',
                            type: 'line',
                            data: Object.values(monthlyCounts),
                            smooth: true,
                            lineStyle: {
                                color: '#36a2eb'
                            },
                            label: {
                                show: true,
                                fontSize: 14, // Adjust font size
                                fontWeight: 'bold', // Adjust font weight
                                color: '#FFFFFF' // Adjust color to be readable on dark background
                            }
                        }
                    ]
                };

                this.monthlyReferralsChart.setOption(option);
            });
        },

        async lookupReferredBy() {
            const referrerIds = [...new Set(this.leads.map(lead => lead.reference_by).filter(id => id))];
            if (referrerIds.length === 0) return;

            try {
                const responses = await Promise.all(referrerIds.map(id => axios.get(`/api/v1/clients/${id}/`)));
                const referrers = responses.reduce((acc, response) => {
                    acc[response.data.id] = response.data.name;
                    return acc;
                }, {});

                this.leads = this.leads.map(lead => ({
                    ...lead,
                    referred_by: referrers[lead.reference_by] || 'Unknown'
                }));
            } catch (error) {
                console.error(error);
            }
        },
        setDateRange(days) {
            this.activeRange = days;
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - days);
            endDate.setDate(endDate.getDate() + 1);
            this.startDate = startDate.toISOString().split('T')[0];
            this.endDate = endDate.toISOString().split('T')[0];
            this.debouncedFetchData();
        },
        debouncedFetchData: debounce(function() {
            this.fetchData();
        }, 300),
        getMedalClass(index) {
            return `fas fa-medal medal-${index}`;
        }
    },
    computed: {
        avgReferralsPerCustomerRounded() {
            if (this.numClients > 0) {
                const avgReferrals = this.numLeads / this.numClients;
                return this.roundToPrecision(avgReferrals, 2);
            }
            return 0;
        },
        referralConversionRate() {
            if (this.numLeads > 0) {
                const wonReferrals = this.leads.filter(lead => lead.status === 'closed').length;
                return this.roundToPrecision((wonReferrals / this.numLeads) * 100, 2);
            }
            return 0;
        },
        valueOpenReferrals() {
            const openReferrals = this.leads.filter(lead => ['new', 'scheduled', 'contacted'].includes(lead.status));
            const totalValue = openReferrals.reduce((sum, lead) => sum + lead.estimated_value, 0);
            return this.roundToPrecision(totalValue, 2);
        },
        valueWonReferrals() {
            const wonReferrals = this.leads.filter(lead => lead.status === 'closed');
            const totalValue = wonReferrals.reduce((sum, lead) => sum + lead.estimated_value, 0);
            return this.roundToPrecision(totalValue, 2);
        },
        totalReferralValue() {
            return this.leads.reduce((sum, lead) => sum + lead.estimated_value, 0);
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
}

.animated-box {
    transition: transform 0.3s, box-shadow 0.3s;
}

.animated-box:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #363636;
    z-index: 2;
    padding: 10px;
    margin-top: -1px; /* Ensures there is no gap */
}

.mini-chart {
    height: 250px;
}

.chart-container {
    position: relative;
    height: 250px; /* Adjust as needed */
    width: 100%;
}

.icon-button {
    display: inline-block;
    padding: 8px;
    margin: 5px 0;
    background-color: #209cee;
    color: white;
    border-radius: 50%;
    text-align: center;
    transition: background-color 0.3s;
}

.icon-button:hover {
    background-color: #006bb3;
    color: white;
}

.button.is-active {
    background-color: #48c774;
    color: white;
}

.live-feed-container {
    border-radius: 8px;
    padding: 10px;
    position: relative;
    max-height: 300px; /* Set a fixed height for the scrollable area */
    overflow-y: auto;
    background: linear-gradient(180deg, #363636, #4a4a4a); /* Gradient background for a dynamic look */
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: #363636;
    z-index: 2;
    padding: 10px;
    margin-top: -1px; /* Ensures there is no gap */
    border-bottom: 2px solid #4a4a4a; /* Adding a subtle border */
}

.live-feed {
    padding: 10px;
    max-height: 240px; /* Ensure the content area is within the container height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.feed-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    border-left: 5px solid #209cee; /* Visual indicator for new items */
    transition: all 0.3s ease-in-out;
}

.feed-item:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-left: 5px solid #48c774; /* Change indicator color on hover */
}

.icon-button {
    display: inline-block;
    padding: 8px;
    background-color: #209cee;
    color: white;
    border-radius: 50%;
    text-align: center;
    transition: background-color 0.3s;
}

.icon-button:hover {
    background-color: #006bb3;
    color: white;
}

.button.rounded-button {
    border-radius: 20px;
    font-size: 1.2em; /* Increase font size */
    padding: 10px 20px; /* Add padding */
}

.field.mb-5 {
    margin-bottom: 20px;
}

.button.mx-2 {
    margin-left: 10px;
    margin-right: 10px;
}

.buttons-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.buttons-group .button {
    margin: 5px;
}

/* Styles for tablets */
@media (max-width: 1024px) {
    .container {
        padding: 0 10px;
    }

    .buttons-group .button {
        font-size: 1em;
        padding: 8px 16px;
    }

    .field.mb-5 {
        margin-bottom: 15px;
    }
}

/* Styles for mobile devices */
@media (max-width: 768px) {
    .container {
        padding: 0 5px;
    }

    .buttons-group {
        flex-direction: column;
    }

    .buttons-group .button {
        width: 100%;
        font-size: 0.9em;
        padding: 8px 16px;
    }

    .field.mb-5 {
        margin-bottom: 10px;
    }

    .box {
        padding: 10px;
    }

    .mini-chart {
        height: 200px;
    }
}
.medal-1 {
    color: #FFD700; /* Gold */
}
.medal-2 {
    color: #C0C0C0; /* Silver */
}
.medal-3 {
    color: #CD7F32; /* Bronze */
}
</style>

