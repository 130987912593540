<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-user"></i> My Account</h1>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle"><i class="fas fa-user-circle"></i> Account Details</h2>
                    <p><strong><i class="fas fa-envelope"></i> Email: </strong>{{ user.username }}</p>
                    <p><strong><i class="fas fa-user"></i> First Name: </strong>{{ user.first_name }}</p>
                    <p><strong><i class="fas fa-user"></i> Last Name: </strong>{{ user.last_name }}</p>
                    <div class="buttons mt-3">
                        <router-link :to="{ name: 'EditMember', params: { id: $store.state.user.id }}" class="button is-light is-rounded animated-button">
                            <i class="fas fa-edit"></i> Edit
                        </router-link>
                        <button @click="logout()" class="button is-danger is-rounded animated-button">
                            <i class="fas fa-sign-out-alt"></i> Log out
                        </button>
                    </div>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle"><i class="fas fa-link"></i> Referral Link</h2>
                    <a :href="ref_link" target="_blank" class="ref-link">{{ ref_link }}</a>
                    <button @click="copy(ref_link)" class="button is-dark is-rounded animated-button mt-2">
                        <i class="fas fa-copy"></i> Copy
                    </button>
                </div>
            </div>

            <div class="column is-6">
                <div class="box qr-box">
                    <h2 class="subtitle"><i class="fas fa-qrcode"></i> Referral QR Code</h2>
                    <div class="qr-code-container">
                        <qrcode-vue :value="ref_link" :size="300" level="H" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import axios from 'axios';
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'MyAccount',
    data() {
        return {
            user: {},
            ref_link: ""
        }
    },
    mounted() {
        this.getUser();
    },
    components: {
        QrcodeVue,
    },
    methods: {
        async logout() {
            await axios
                .post('/api/v1/token/logout/')
                .then(response => {
                    console.log('Logged out');
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });

            axios.defaults.headers.common['Authorization'] = '';
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('userid');
            localStorage.removeItem('team_name');
            localStorage.removeItem('team_id');
            this.$store.commit('removeToken');

            this.$router.push('/thankyou');
        },
        async getUser() {
            this.$store.commit('setIsLoading', true);

            const userID = this.$store.state.user.id;

            await axios
                .get(`/api/v1/teams/member/${userID}/`)
                .then(response => {
                    this.user = response.data;
                    this.ref_link = "https://app.refrme.io/customers/referral/" + this.user.profile.code;
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async copy(mytext) {
            try {
                await navigator.clipboard.writeText(mytext);
                alert('Copied');
            } catch ($e) {
                alert('Cannot copy');
            }
        },
        async copyQr() {
            try {
                await this.canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]));
                alert('Copied');
            } catch ($e) {
                alert('Cannot copy');
            }
        }
    }
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.box:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.animated-button {
    transition: transform 0.3s, background-color 0.3s;
}

.animated-button:hover {
    transform: scale(1.05);
    background-color: #006bb3;
}

.button.is-light.is-rounded,
.button.is-danger.is-rounded,
.button.is-dark.is-rounded {
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.button.is-light.is-rounded:hover {
    background-color: #e2e2e2;
}

.button.is-danger.is-rounded:hover {
    background-color: #ff1744;
}

.button.is-dark.is-rounded:hover {
    background-color: #333333;
}

.buttons.mt-3 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.qr-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-code-container {
    margin-top: 20px;
}

.ref-link {
    word-wrap: break-word;
    word-break: break-all;
    display: block;
}

</style>
