<template>
    <div class="container">
      <div class="columns is-multiline">
        <!-- <template v-if="selected_incentive != null">
          <div class="notification is-info is-rounded">
            Receive <strong>{{ selected_incentive.offer }}</strong> with every successful referral!
          </div>
        </template> -->
  
        <div class="column is-12">
          <h1 class="title"><i class="fas fa-user-plus mr-2"></i> New Customer</h1>
          <strong>Add your information below</strong>
        </div>
  
        <div class="column is-12">
          <form @submit.prevent="submitForm">
            <div class="field">
              <label> <i class="fa-solid fa-user-tie"></i> Preferred Team Member? (optional)</label>
              <div class="control">
                <v-select
                  :options="members"
                  label="first_name"
                  class="rounded-select"
                  placeholder=""
                  v-model="selected_ref_code"
                  :reduce="selectedRefCode => selectedRefCode && selectedRefCode.profile ? selectedRefCode.profile.code : ''"
                  :dropdown-should-open="dropdownShouldOpen"
                >
                  <template #option="{ first_name, last_name }">
                    <h3 style="margin: 0">{{ first_name }} {{ last_name }}</h3>
                  </template>
                  <template #selected-option="{ first_name, last_name }">
                    <h3 style="margin: 0">{{ first_name }} {{ last_name }}</h3>
                  </template>
                </v-select>
              </div>
            </div>
  
            <div class="field">
              <label><i class="fa-regular fa-user"></i> Name</label>
              <div class="control">
                <input type="text" class="input is-rounded" v-model="name" required>
              </div>
            </div>
  
            <div class="field">
              <label><i class="fa-solid fa-envelope"></i> Email</label>
              <div class="control">
                <input type="email" class="input is-rounded" v-model="email" required>
              </div>
            </div>
  
            <div class="field">
              <label><i class="fa-solid fa-mobile"></i> Phone</label>
              <div class="control">
                <input type="text" class="input is-rounded" v-model="phone" required>
              </div>
            </div>
  
            <div class="field">
              <label><i class="fa-solid fa-bell-concierge"></i> Desired Service or Additional Information?</label>
              <div class="control">
                <input type="text" class="input is-rounded" v-model="website">
              </div>
            </div>
  
            <div class="field">
              <div class="control">
                <button class="button is-primary is-rounded"><i class="fas fa-paper-plane mr-2"></i> Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'AddClientWithReferral',
  data() {
    return {
      name: '',
      contact_person: '',
      email: '',
      phone: '',
      estimated_value: 0,
      website: '',
      addReferral: false,
      existingCustomer: null,
      ref_code: this.$route.params.ref_code,
      team: {},
      selected_incentive: null,
      selected_ref_code: null,
      members: []
    }
  },
  mounted() {
    this.getTeam()
  },
  components: {
    vSelect
  },
  methods: {
    async getTeam() {
      this.$store.commit('setIsLoading', true)

      const ref_code_dict = {
        ref_code: this.$route.params.ref_code
      }

      await axios
        .post('/api/v1/teams/get_my_team_with_referral/', ref_code_dict)
        .then(response => {
          this.team = response.data
          this.members = response.data.members
          this.ref_code = this.$route.params.ref_code
          this.selected_incentive = response.data.incentives[Object.keys(response.data.incentives)[0]]
        })
        .catch(error => {
          console.log(error)
        })

      this.$store.commit('setIsLoading', false)
    },
    async submitForm() {
      this.$store.commit('setIsLoading', true)

      if (this.selected_ref_code != null & this.selected_ref_code != "") {
        this.ref_code = this.selected_ref_code
      }

      const client = {
        name: this.name,
        contact_person: this.contact_person,
        email: this.email,
        phone: this.phone,
        website: this.website,
        ref_code: this.ref_code,
        status: 'new'
      }

      this.$posthog.identify(this.email,{ // Replace "distinct_id" with your user's unique identifier
                        email: this.email, // optional: set additional person properties
                        name: this.name,
                        ref_code: this.ref_code,
                        team: this.team,
                        status: 'new'
                    })

      await axios
        .post('/api/v1/clients/referral/add', client)
        .then(response => {
          if (response.data.message === 'The customer was added') {
            toast({
              message: 'The customer was added',
              type: 'is-primary is-rounded',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
            if (this.addReferral) {
              this.$router.push(`/referrals/referral/${client.ref_code}/${response.data.client_id}`)
            } else {
              this.$router.push('/customer/Thankyou')
            }
          } else {
            toast({
              message: response.data.message,
              type: 'is-warning',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          }
        })
        .catch(error => {
          toast({
            message: error.response ? error.response.data.message : 'An error occurred',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        })

      this.$store.commit('setIsLoading', false)
    }
  }
}
</script>
<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
  margin-top: 20px;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.is-large.is-rounded {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.mr-2 {
  margin-right: 10px;
}
.rounded-select .vs__dropdown-toggle, 
.rounded-select .vs__dropdown-menu {
  border-radius: 15px;
}

.rounded-select .vs__selected-options {
  border-radius: 15px;
}

.rounded-select .vs__search {
  border-radius: 15px;
}

.rounded-select .vs__open-indicator {
  border-radius: 15px;
}
</style>
  
