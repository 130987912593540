<template>
    <div class="container">
        <div class="columns is-multiline">
            <template v-if="this.selected_incentive != null">
                <div class="notification is-info is-rounded">
                    <i class="fas fa-gift mr-2"></i> 
                    <span>Receive <strong>{{ this.selected_incentive.offer }}</strong> with every successful referral!</span>
                </div>
            </template>
            <div class="column is-12 has-text-centered">
                <h1 class="title">Add a Referral</h1>
                <p class="subtitle">Select an option to proceed:</p>
            </div>
            <div class="column is-12">
                <div class="field has-text-centered">
                    <router-link :to="`/customers/referral/${ref_code}/existing`" class="button is-large is-warning is-rounded mt-6 is-fullwidth">
                        <i class="fas fa-user-friends mr-2"></i> I've added a Referral before
                    </router-link>
                </div>
                <div class="field has-text-centered">
                    <router-link :to="`/customers/referral/${ref_code}/new`" class="button is-large is-info is-rounded mt-6 is-fullwidth">
                        <i class="fas fa-user-plus mr-2"></i> This is my first Referral
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: 'AddClientWithReferral',
    data() {
        return {
            name: '',
            contact_person: '',
            email: '',
            phone: '',
            estimated_value: 0,
            website: '',
            addReferral: false,
            existingCustomer: null,
            ref_code: this.$route.params.ref_code,
            team: {},
            selected_incentive: null
        }
    },
    mounted() {
        this.getTeam()
    },
    components: {
        vSelect
    },
    methods: {
        dropdownShouldOpen(VueSelect) {
            if (this.existingCustomer !== null) {
                return VueSelect.open
            }
            return VueSelect.search.length !== 0 && VueSelect.open
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)
            
            const ref_code_dict = {
                ref_code: this.$route.params.ref_code
            }
            
            await axios
                .post('/api/v1/teams/get_my_team_with_referral/', ref_code_dict)
                .then(response => {
                    this.team = response.data
                    this.ref_code = this.$route.params.ref_code
                    this.selected_incentive = response.data.incentives.find(incentive => incentive.is_active)
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    padding: 10px 15px;
    text-align: center;
    margin-bottom: 20px;
}

.notification i {
    flex-shrink: 0; /* Prevent the icon from shrinking */
}

.notification span {
    flex: 1;
    text-align: left; /* Align text to the left for better readability */
}

.title {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 1.3em;
    text-align: center;
    margin-bottom: 20px;
}

.button.is-large.is-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    padding: 15px;
}

.button.is-fullwidth {
    width: 100%;
}

.field.has-text-centered {
    text-align: center;
    margin-bottom: 20px;
}

.mr-2 {
    margin-right: 10px;
}
</style>


