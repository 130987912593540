<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Thank you</h1>
            </div>

            <div class="column is-4">
                <p>Thank you for submiting your information!</p>
            </div>
        </div>
    </div>
</template>
