<template>
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <h1 class="title has-text-centered">
            <i class="fas fa-user-plus mr-2"></i> Sign up
          </h1>
          <form @submit.prevent="submitForm">
            <div class="field">
              <label class="label">Email</label>
              <div class="control has-icons-left">
                <input type="email" name="email" class="input is-rounded" v-model="username">
                <span class="icon is-left">
                  <i class="fas fa-envelope"></i>
                </span>
              </div>
            </div>
  
            <div class="field">
              <label class="label">Password</label>
              <div class="control has-icons-left">
                <input type="password" name="password1" class="input is-rounded" v-model="password1">
                <span class="icon is-left">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
            </div>
  
            <div class="field">
              <label class="label">Repeat password</label>
              <div class="control has-icons-left">
                <input type="password" name="password2" class="input is-rounded" v-model="password2">
                <span class="icon is-left">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
              <div class="content">
                <ul>
                  <li>Your password can’t be too similar to your other personal information.</li>
                  <li>Your password must contain at least 8 characters.</li>
                  <li>Your password can’t be a commonly used password.</li>
                  <li>Your password can’t be entirely numeric.</li>
                </ul>
              </div>
            </div>
  
            <div class="notification is-danger is-rounded" v-if="errors.length">
              <p v-for="error in errors" :key="error">{{ error }}</p>
            </div>
  
            <div class="field">
              <div class="control">
                <button class="button is-primary is-rounded is-fullwidth">
                  <i class="fas fa-user-plus mr-2"></i> Submit
                </button>
              </div>
            </div>
  
            <div class="field has-text-centered">
              <button class="button is-text" @click="goToLogin">
                Already have an account?
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  
  export default {
    name: 'SignUp',
    data() {
      return {
        username: '',
        password1: '',
        password2: '',
        errors: []
      }
    },
    methods: {
      async submitForm() {
        this.errors = []
  
        if (this.username === '') {
          this.errors.push('The email is missing')
        }
  
        if (this.password1 === '') {
          this.errors.push('The password is too short')
        }
  
        if (this.password1 !== this.password2) {
          this.errors.push('The passwords are not matching')
        }
  
        if (!this.errors.length) {
          this.$store.commit('setIsLoading', true)
  
          const formData = {
            username: this.username,
            password: this.password1
          }
  
          await axios
            .post('/api/v1/users/', formData)
            .then(response => {
              toast({
                message: 'Account was created, please log in',
                type: 'is-primary is-rounded',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
  
              this.$router.push('/log-in')
            })
            .catch(error => {
              if (error.response) {
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                }
              } else if (error.message) {
                this.errors.push('Something went wrong. Please try again!')
              }
            })
  
          this.$store.commit('setIsLoading', false)
        }
      },
      goToLogin() {
        this.$router.push('/log-in')
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import "~@fortawesome/fontawesome-free/css/all.min.css";
  
  .container {
    margin-top: 50px;
  }
  
  .title {
    margin-bottom: 30px;
  }
  
  .field {
    margin-bottom: 20px;
  }
  
  .button.is-primary.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
  }
  
  .button.is-primary.is-rounded:hover {
    background-color: #1a8cc2;
  }
  
  .icon.is-left {
    left: 10px;
  }
  
  .button.is-text {
    background: none;
    border: none;
    color: #209cee;
    font-size: 1em;
    cursor: pointer;
  }
  
  .button.is-text:hover {
    text-decoration: underline;
  }
  
  .content ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  </style>
  
