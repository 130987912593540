<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fa-solid fa-user"></i> {{ client.name }}</h1>
                <div class="buttons">
                    <button @click="goBack" class="button is-light is-rounded">
                        <i class="fas fa-arrow-left"></i> Back
                    </button>
                    <button v-if="!isEditing" @click="isEditing = true" class="button is-light is-rounded">
                        <i class="fas fa-edit"></i> Edit
                    </button>
                    <button v-else @click="saveClient" class="button is-primary is-rounded">
                        <i class="fas fa-save"></i> Save
                    </button>
                    <button v-if="isEditing" @click="cancelEdit" class="button is-light is-rounded">
                        <i class="fas fa-times"></i> Cancel
                    </button>
                    <button @click="showDeleteModal = true" class="button is-danger is-rounded">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="title"><i class="fas fa-info-circle"></i> Details</h2>
                    <div class="field">
                        <label class="label"><i class="fas fa-user"></i> Assigned to:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ client.created_by ? client.created_by.first_name + ' ' + client.created_by.last_name : 'Not Assigned' }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="client.created_by">
                                    <option value="" selected>Select member</option>
                                    <option v-for="member in team.members" :key="member.id" :value="member.id">
                                        {{ member.first_name }} {{ member.last_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-user-friends"></i> Referred By:</label>
                        <div class="control">
                            <div v-if="reference_by"><router-link :to="{ name: 'Client', params: { id: reference_by.id }}">{{ reference_by.name }}</router-link></div>
                            <div v-else>Not Available</div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-exclamation-circle"></i> Status:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ client.status }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="client.status">
                                    <option value="new">New</option>
                                    <option value="contacted">Contacted</option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="active">Active</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-calendar-alt"></i> Created on:</label>
                        <div class="control">
                            <div>{{ new Date(client.created_at).toDateString() }}</div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-calendar-alt"></i> Modified on:</label>
                        <div class="control">
                            <div>{{ new Date(client.modified_at).toDateString() }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="title"><i class="fas fa-address-book"></i> Contact Information</h2>
                    <div class="field">
                        <label class="label"><i class="fas fa-map-marker-alt"></i> Address:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ client.contact_person }}</div>
                            <div v-else>
                                <input type="text" class="input is-rounded" v-model="client.contact_person">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-envelope"></i> Email:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ client.email }}</div>
                            <div v-else>
                                <input type="email" class="input is-rounded" v-model="client.email">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-phone"></i> Phone:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ client.phone }}</div>
                            <div v-else>
                                <input type="text" class="input is-rounded" v-model="client.phone">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-info-circle"></i> Desired Service or Additional Information:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ client.website }}</div>
                            <div v-else>
                                <input type="text" class="input is-rounded" v-model="client.website">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-hand-point-right"></i> Contact Preference:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ client.contact_preference }}</div>
                            <div v-else class="select is-rounded">
                                <select v-model="client.contact_preference">
                                    <option value="Email">Email</option>
                                    <option value="Phone">Phone</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><i class="fas fa-calendar-check"></i> Last Contacted:</label>
                        <div class="control">
                            <div v-if="!isEditing">{{ formattedLastContactedDisplay }}</div>
                            <div v-else>
                                <input type="datetime-local" class="input is-rounded" v-model="formattedLastContacted">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                <h2 class="title"><i class="fas fa-link"></i> Referrals</h2>
                <div class="box animated-box" v-for="reference in client.reference" :key="reference.id">
                    <div class="is-flex is-align-items-center">
                    <h3 class="is-size-4 mr-2">{{ reference.company }}</h3>
                    <span 
                        class="status-indicator ml-auto" 
                        :class="statusClass(reference)"
                    >
                        {{ statusMessage(reference) }}
                    </span>
                    </div>
                    <p><strong>Email:</strong> {{ reference.email }}</p>
                    <p><strong>Status:</strong> {{ reference.status }}</p>
                    <router-link :to="{ name: 'Lead', params: { id: reference.id }}" class="button is-info is-rounded mt-2">
                    <i class="fas fa-info-circle"></i>
                    </router-link>
                </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h2 class="title"><i class="fas fa-sticky-note"></i> Notes</h2>
                    <router-link :to="{ name: 'AddNote', params: { id: client.id }}" class="button is-primary is-rounded mb-6"><i class="fas fa-plus"></i> Add Note</router-link>
                    <div class="box animated-box" v-for="note in notes" :key="note.id">
                        <h3 class="is-size-4">{{ note.name }}</h3>
                        <p>{{ note.body }}</p>
                        <router-link :to="{ name: 'EditNote', params: { id: client.id, note_id: note.id }}" class="button is-primary is-rounded mt-6"><i class="fas fa-edit"></i> Edit Note</router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Confirmation Modal -->
        <div class="modal" :class="{ 'is-active': showDeleteModal }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="box">
                    <p>Are you sure you want to delete this customer?</p>
                    <div class="buttons">
                        <button class="button is-danger" @click="deleteClient">Yes, Delete</button>
                        <button class="button" @click="showDeleteModal = false">Cancel</button>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="showDeleteModal = false"></button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
    name: 'Client',
    data() {
        return {
            client: {},
            notes: [],
            team: { members: [] },
            isEditing: false,
            showDeleteModal: false,
            reference_by: null,
        };
    },
    mounted() {
        this.getClient();
        this.getTeam();
    },
    watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        this.getClient(); // Method to fetch client details
      }
    }
  },
    computed: {
        formattedLastContacted: {
            get() {
                return this.client.last_contacted ? new Date(this.client.last_contacted).toISOString().substring(0, 16) : '';
            },
            set(value) {
                this.client.last_contacted = value ? new Date(value).toISOString() : '';
            },
        },
        formattedLastContactedDisplay() {
            return this.client.last_contacted ? new Date(this.client.last_contacted).toLocaleString() : 'Not Contacted';
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async deleteClient() {
            this.$store.commit('setIsLoading', true);

            const clientID = this.$route.params.id;

            await axios
                .post(`/api/v1/clients/delete_client/${clientID}/`)
                .then((response) => {
                    console.log(response.data);
                    this.$router.push('/dashboard/customers');
                })
                .catch((error) => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        statusClass(reference) {
            if (reference.customer_payment_status === 'complete') {
                return 'status-complete';
            } else if (reference.status === 'closed' && reference.customer_payment_status !== 'complete') {
                return 'status-redeem';
            } else if (reference.status !== 'closed' && reference.customer_payment_status !== 'complete') {
                return 'status-not-redeemable';
            } else {
                return 'status-pending';
            }
        },
        statusMessage(reference) {
            if (reference.customer_payment_status === 'complete') {
                return 'Already Redeemed';
            } else if (reference.status === 'closed' && reference.customer_payment_status !== 'complete') {
                return 'Incentive Able to be Redeemed';
            } else if (reference.status !== 'closed') {
                return 'Not Able to Redeem';
            } else {
                return reference.customer_payment_status;
            }
            },
        async getClient() {
            this.$store.commit('setIsLoading', true);

            const clientID = this.$route.params.id;
            this.reference_by = null;

            await axios
                .get(`/api/v1/clients/${clientID}/`)
                .then((response) => {
                    this.client = response.data;
                    if (this.client.last_contacted) {
                        this.client.last_contacted = new Date(this.client.last_contacted).toISOString().substring(0, 16);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            await axios
                .get(`/api/v1/notes/?client_id=${clientID}`)
                .then((response) => {
                    this.notes = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });

            await axios
            .get(`/api/v1/clients/${this.client.reference_by}`)
            .then((response) => {
                this.reference_by = response.data;
            })
            .catch((error) => {
                console.log(error);
            });


            this.$store.commit('setIsLoading', false);
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then((response) => {
                    this.team = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.team = { members: [] };
                });

            this.$store.commit('setIsLoading', false);
        },
        async saveClient() {
            this.$store.commit('setIsLoading', true);

            const clientID = this.$route.params.id;

            // Ensure last_contacted is properly formatted before sending
            if (this.client.last_contacted) {
                this.client.last_contacted = new Date(this.client.last_contacted).toISOString();
            }

            await axios
                .patch(`/api/v1/clients/${clientID}/`, this.client)
                .then((response) => {
                    toast({
                        message: 'Client information updated successfully',
                        type: 'is-primary is-rounded',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });
                    this.isEditing = false;
                    this.getClient();
                })
                .catch((error) => {
                    console.log(error);
                    let errorMessage = 'Failed to update Customer information. Please try again.'
                    if (error.response && error.response.data) {
                        // Extract and format error details
                        const errors = error.response.data
                        errorMessage = Object.keys(errors)
                            .map(key => `${key}: ${errors[key].join(', ')}`)
                            .join(' ')
                    }
                    toast({
                        message: errorMessage,
                        type: 'is-danger is-rounded',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                });

            this.$store.commit('setIsLoading', false);
        },
        cancelEdit() {
            this.isEditing = false;
            this.getClient();
        },
    },
};

</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.box:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button.is-light.is-rounded,
.button.is-info.is-rounded,
.button.is-danger.is-rounded,
.button.is-primary.is-rounded {
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.button.is-light.is-rounded:hover {
    background-color: #e2e2e2;
}

.button.is-info.is-rounded:hover {
    background-color: #1a8cc2;
}

.button.is-danger.is-rounded:hover {
    background-color: #ff1744;
}

.button.is-primary.is-rounded:hover {
    background-color: #0d6efd;
}

.label {
    font-size: 1.2em;
    color: #363636;
}

.input.is-rounded,
.select.is-rounded select {
    border-radius: 20px;
}

.field:not(:last-child) {
    margin-bottom: 20px;
}
.status-indicator {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 15px;
  font-size: 0.85em;
  font-weight: bold;
  color: white;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.status-complete {
  background-color: #4caf50; /* Green */
}

.status-redeem {
  background-color: #2196f3; /* Blue */
}

.status-not-redeemable {
  background-color: #9e9e9e; /* Grey */
}

.status-indicator:hover {
  transform: scale(1.05);
}

</style>