import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'

import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import ResetPasswordRequest from '../views/ForgotPassword.vue'
import ResetPasswordRequestConfirm from '../views/PasswordResetConfirm.vue'
import OfferBuilder from '../views/dashboard/OfferBuilder.vue'
import SendOffers from '../views/dashboard/SendOffers.vue'
import SendText from '../views/dashboard/SendText.vue'
import Incentive from '../views/dashboard/Incentive.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import Leads from '../views/dashboard/Leads.vue'
import Lead from '../views/dashboard/Lead.vue'
import AddLead from '../views/dashboard/AddLead.vue'
import AddLeadWithReferral from '../views/dashboard/AddLeadWithReferral.vue'
import EditLead from '../views/dashboard/EditLead.vue'
import AddTeam from '../views/dashboard/AddTeam.vue'
import Team from '../views/dashboard/Team.vue'
import AddMember from '../views/dashboard/AddMember.vue'
import Clients from '../views/dashboard/Clients.vue'
import AddClient from '../views/dashboard/AddClient.vue'
import AddClientWithReferral from '../views/dashboard/AddClientWithReferral.vue'
import LogOutThankyou from '../views/dashboard/LogOutThankyou'
import AddClientWithReferralExisting from '../views/dashboard/AddClientWithReferralExistingCustomer.vue'
import AddClientWithReferralNew from '../views/dashboard/AddClientWithReferralNewCustomer.vue'
import NavNewExisting from '../views/dashboard/NavExistingorNew.vue'
import Client from '../views/dashboard/Client.vue'
import EditClient from '../views/dashboard/EditClient.vue'
import AddNote from '../views/dashboard/AddNote.vue'
import EditNote from '../views/dashboard/EditNote.vue'
import EditMember from '../views/dashboard/EditMember.vue'
import Plans from '../views/dashboard/Plans.vue'
import PlansThankyou from '../views/dashboard/PlansThankyou.vue'
import ReferralThankyou from '../views/dashboard/ReferralThankyou.vue'
import CustomerThankyou from '../views/dashboard/CustomerThankyou.vue'
import AddClientWithNewCustomer from '../views/dashboard/AddClientWithNewCustomer.vue'
import NavNewCustomerorReferral from '../views/dashboard/NavNewCustomerorReferral.vue'
import TeamReferralBoard from '../views/boards/TeamReferralBoard.vue'
import MyReferralBoard from '../views/boards/MyReferralBoard.vue'

const routes = [
  {
    path: '/',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/offer-builder',
    name: 'OfferBuilder',
    component: OfferBuilder,
    meta: {
      requireLogin: true
    }
  },
  {
  path: '/dashboard/send-offers',
  name: 'SendOffers',
  component: SendOffers,
  meta: {
    requireLogin: true
  }
  },
  {
    path: '/dashboard/send-text',
    name: 'SendText',
    component: SendText,
    meta: {
      requireLogin: true
    }
    },
  {
    path: '/dashboard/incentive',
    name: 'Incentive',
    component: Incentive,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team',
    name: 'Team',
    component: Team,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team/plans',
    name: 'Plans',
    component: Plans,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team/plans/thankyou',
    name: 'PlansThankyou',
    component: PlansThankyou,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/thankyou',
    name: 'LogOutThankyou',
    component: LogOutThankyou,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/referral/thankyou',
    name: 'ReferralThankyou',
    component: ReferralThankyou,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/customer/thankyou',
    name: 'CustomerThankyou',
    component: CustomerThankyou,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/dashboard/edit-member/:id',
    name: 'EditMember',
    component: EditMember,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/team/add-member',
    name: 'AddMember',
    component: AddMember,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/add-team',
    name: 'AddTeam',
    component: AddTeam,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/referrals',
    name: 'Leads',
    component: Leads,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/myreferralboard',
    name: 'MyReferralBoard',
    component: MyReferralBoard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/teamreferralboard',
    name: 'TeamReferralBoard',
    component: TeamReferralBoard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/referrals/add',
    name: 'AddLead',
    component: AddLead,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/leads/:id',
    redirect: to => {
      const { id } = to.params;
      return `/dashboard/referrals/${id}`;
    }
  },
  {
    path: '/dashboard/referrals/:id',
    name: 'Lead',
    component: Lead,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/referrals/referral/:ref_code/:id',
    name: 'AddLeadWithReferral',
    component: AddLeadWithReferral,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/dashboard/referrals/:id/edit',
    name: 'EditLead',
    component: EditLead,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/customers',
    name: 'Clients',
    component: Clients,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/customers/add',
    name: 'AddClient',
    component: AddClient,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/:id',
    redirect: to => {
      const { id } = to.params;
      return `/dashboard/customers/${id}`;
    }
  },
  {
    path: '/dashboard/customers/:id',
    name: 'Client',
    component: Client,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/clients/referral/:id',
    redirect: to => {
      const { id } = to.params;
      return `/customers/referral/${id}`;
    }
  },
  {
    path: '/customers/referral/:ref_code',
    name: 'NavNewCustomerorReferral',
    component: NavNewCustomerorReferral,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/customers/referral/:ref_code/nav',
    name: 'NavNewExisting',
    component: NavNewExisting,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/customers/referral/:ref_code/existing',
    name: 'AddClientWithReferralExisting',
    component: AddClientWithReferralExisting,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/customers/referral/:ref_code/new',
    name: 'AddClientWithReferralNew',
    component: AddClientWithReferralNew,
    meta: {
      requireLogin: false
    }
  },
  {
  path: '/customers/referral/:ref_code/new_customer',
  name: 'AddClientWithNewCustomer',
  component: AddClientWithNewCustomer,
  meta: {
    requireLogin: false
  }
  },
  {
    path: '/dashboard/customers/:id/edit',
    name: 'EditClient',
    component: EditClient,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/customers/:id/add-note',
    name: 'AddNote',
    component: AddNote,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/customers/:id/edit-note/:note_id',
    name: 'EditNote',
    component: EditNote,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordRequest',
    component: ResetPasswordRequest,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/reset-password-confirm/:uid/:token',
    name: 'ResetPasswordRequestConfirm',
    component: ResetPasswordRequestConfirm,
    meta: {
      requireLogin: false
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated && to.AddClientWithReferral && to.AddLeadWithReferral && to.ReferralThankyou && to.CustomerThankyou ){
//     next('/log-in')
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
      next({
          path: '/log-in',
          query: { redirect: to.fullPath } // Store the intended destination
      });
  } else if (store.state.subscriptionStatus === 'inactive' && to.path !== '/dashboard/team/plans') {
      next('/dashboard/team/plans');
  } else {
      next();
  }
})

export default router
