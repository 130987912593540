<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-gift mr-2"></i> {{ team.name }} Offer Builder</h1>
                
                <div class="field">
                    <label class="label">Templates</label>
                    <div class="control">
                        <div class="select is-rounded">
                            <select v-model="selected_template">
                                <option value="" selected>Select Template</option>
                                <option v-for="member in team.templates" v-bind:key="member.id" v-bind:value="member.id">
                                    {{ member.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <button class="button is-light is-rounded" @click="loadTemplate()">
                            <i class="fas fa-download mr-2"></i> Load Template
                        </button>
                    </div>
                </div>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label class="label">Template Name</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="name">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Subject</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="subject">
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">Body</label>
                        <div id="app" class="control">
                            <QuillEditor ref="editor" theme="snow" v-model:content="content" content-type="html" toolbar="full"/>
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-primary is-rounded">
                                <i class="fas fa-save mr-2"></i> Update Template
                            </button>
                        </div>
                    </div>
                </form>

                <div class="field">
                    <div class="control">
                        <button class="button is-dark is-rounded" @click="create_template()">
                            <i class="fas fa-plus-circle mr-2"></i> Create Template
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { toast } from 'bulma-toast'

export default {
    name: 'OfferBuilder',
    components: {
        QuillEditor
    },
    data() {
        return {
            lead: {},
            content: '',
            subject: '',
            name: '',
            leads: [],
            clients: [],
            num_leads: 0,
            num_clients: 0,
            team: {},
            selected_template: '',
        }
    },
    mounted() {
        this.getTeam()
        this.getClients()
    },
    methods: {
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const data = {
                content: this.content,
                subject: this.subject,
                name: this.name,
                id: this.selected_template
            }

            await axios
                .post(`/api/v1/teams/update_template/`, data)
                .then(response => {
                    if (response.data.message == 'The template was saved') {
                        toast({
                            message: 'The template was saved',
                            type: 'is-primary is-rounded',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                        this.$router.push({ name: 'OfferBuilder' })
                    } else {
                        toast({
                            message: response.data.message,
                            type: 'is-warning',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            window.location.reload();
            this.$store.commit('setIsLoading', false)
        },
        async create_template() {
            this.$store.commit('setIsLoading', true)

            const data = {
                content: this.content,
                subject: this.subject,
                name: this.name,
                id: this.selected_template
            }

            await axios
                .post(`/api/v1/teams/create_template/`, data)
                .then(response => {
                    if (response.data.message == 'The template was saved') {
                        toast({
                            message: 'The template was saved',
                            type: 'is-primary is-rounded',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                        this.$router.push({ name: 'OfferBuilder' })
                    } else {
                        toast({
                            message: response.data.message,
                            type: 'is-warning',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            window.location.reload();
            this.$store.commit('setIsLoading', false)
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data
                    // this.clients = response.data.clients
                    // this.num_clients = response.data.clients.count
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        },
        async getClients() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/clients/all_clients/')
                .then(response => {
                    this.clients = response.data
                    this.num_clients = response.data.count
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async loadTemplate() {
            this.$store.commit('setIsLoading', true)

            const data = {
                id: this.selected_template
            }

            await axios
                .post('/api/v1/teams/get_template/', data)
                .then(response => {
                    this.$refs.editor.setHTML(response.data.content)
                    this.name = response.data.name
                    this.subject = response.data.subject
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.field {
    margin-bottom: 20px;
}

.label {
    font-size: 1.1em;
    color: #363636;
}

.button.is-light.is-rounded {
    background-color: #f5f5f5;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-light.is-rounded:hover {
    background-color: #e2e2e2;
}

.button.is-primary.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-primary.is-rounded:hover {
    background-color: #1a8cc2;
}

.button.is-dark.is-rounded {
    background-color: #363636;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-dark.is-rounded:hover {
    background-color: #2a2a2a;
}

.mr-2 {
    margin-right: 8px;
}
</style>
