<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-edit"></i> Edit {{ client.name }}</h1>
            </div>

            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label><i class="fas fa-user"></i> Name</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="client.name">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-map-marker-alt"></i> Address</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="client.contact_person">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-envelope"></i> Email</label>
                        <div class="control">
                            <input type="email" class="input is-rounded" v-model="client.email">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-phone"></i> Phone</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="client.phone">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-info-circle"></i> Status</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="client.status">
                                    <option value="new">New</option>
                                    <option value="contacted">Contacted</option>
                                    <option value="scheduled">Scheduled</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-question-circle"></i> How can we help you?</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="client.website">
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-handshake"></i> Contact Preference</label>
                        <div class="control">
                            <div class="select is-rounded">
                                <select v-model="client.contact_preference">
                                    <option value="Email">Email</option>
                                    <option value="Phone">Phone</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label><i class="fas fa-calendar-alt"></i> Last Contacted</label>
                        <div class="control">
                            <input type="datetime-local" class="input is-rounded" v-model="client.last_contacted">
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-primary is-rounded"><i class="fas fa-save"></i> Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'EditClient',
    data() {
        return {
            client: {}
        }
    },
    mounted() {
        this.getClient()
    },
    methods: {
        async getClient() {
            this.$store.commit('setIsLoading', true)

            const clientID = this.$route.params.id

            axios
                .get(`/api/v1/clients/${clientID}/`)
                .then(response => {
                    this.client = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const clientID = this.$route.params.id

            axios
                .patch(`/api/v1/clients/${clientID}/`, this.client)
                .then(response => {
                    toast({
                        message: 'The customer was updated',
                        type: 'is-primary is-rounded',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push(`/dashboard/customers/${clientID}`)
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s;
}

.box:hover {
    transform: scale(1.03);
}

.button.is-primary.is-rounded,
.button.is-danger.is-rounded,
.button.is-light.is-rounded,
.router-link-active {
    border-radius: 20px;
    font-size: 1.1em;
    padding: 10px 20px;
}

.button.is-primary.is-rounded:hover,
.button.is-danger.is-rounded:hover,
.button.is-light.is-rounded:hover {
    transform: scale(1.05);
}

.label {
    font-size: 1.2em;
    color: #363636;
}

.animated-box {
    transition: transform 0.3s, box-shadow 0.3s;
}

.animated-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
</style>
