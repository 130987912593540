<template>
    <nav class="navbar is-dark is-rounded">
        <div class="navbar-brand">
            <router-link to="/log-in" class="navbar-item">
                <img src="../../assets/logo2.png" alt="Logo">
            </router-link>
            <a role="button" class="navbar-burger" :class="{ 'is-active': isOpen }" @click="toggleMenu" aria-label="menu" aria-expanded="false">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div class="navbar-menu" :class="{ 'is-active': isOpen }">
            <div class="navbar-start">
                <template v-if="$store.state.isAuthenticated">
                    <router-link to="/dashboard/" class="navbar-item"><i class="fas fa-chart-bar mr-2"></i> Insights</router-link>
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link" @click="toggleDropdown('offerDropdown')">
                            <i class="fas fa-gift mr-2"></i> Offers
                        </a>
                        <div class="navbar-dropdown" :class="{ 'is-hidden': !dropdowns.offerDropdown }">
                            <router-link to="/dashboard/offer-builder" class="navbar-item"><i class="fas fa-tools mr-2"></i> Offer Builder</router-link>
                            <router-link to="/dashboard/send-offers" class="navbar-item"><i class="fas fa-envelope mr-2"></i> Send Email Offers</router-link>
                            <router-link to="/dashboard/send-text" class="navbar-item"><i class="fas fa-sms mr-2"></i> Send Text Offers</router-link>
                        </div>
                    </div>
                    <!-- <router-link to="/dashboard/leads" class="navbar-item"><i class="fas fa-handshake mr-2"></i> Referrals</router-link> -->
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link" @click="toggleDropdown('referralDropdown')">
                            <i class="fas fa-handshake mr-2"></i> Referrals
                        </a>
                        <div class="navbar-dropdown" :class="{ 'is-hidden': !dropdowns.referralDropdown }">
                            <router-link to="/dashboard/referrals" class="navbar-item"><i class="fas fa-users-cog mr-2"></i> Referrals</router-link>
                            <router-link to="/dashboard/myreferralboard" class="navbar-item"><i class="fas fa-user mr-2"></i> My Referral Board</router-link>
                            <router-link  to="/dashboard/teamreferralboard" class="navbar-item"><i class="fas fa-user mr-2"></i> Team Referral Board</router-link>
                        </div>
                    </div>
                    <router-link to="/dashboard/customers" class="navbar-item"><i class="fas fa-user-friends mr-2"></i> Customers</router-link>
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link" @click="toggleDropdown('teamDropdown')">
                            <i class="fas fa-users mr-2"></i> Team
                        </a>
                        <div class="navbar-dropdown" :class="{ 'is-hidden': !dropdowns.teamDropdown }">
                            <router-link to="/dashboard/team" class="navbar-item"><i class="fas fa-users-cog mr-2"></i> My Team</router-link>
                            <router-link to="/dashboard/my-account" class="navbar-item"><i class="fas fa-user mr-2"></i> My Referral Link</router-link>
                            <router-link to="/dashboard/incentive" class="navbar-item"><i class="fas fa-trophy mr-2"></i> Referral Incentive</router-link>
                        </div>
                    </div>
                </template>
            </div>
            <div class="navbar-end">
                <div class="navbar-item">
                    <div class="buttons">
                        <template v-if="!$store.state.isAuthenticated">
                            <a href="https://refrme.io" class="button is-info is-rounded"><i class="fas fa-home mr-2"></i> Home</a>
                            <router-link to="/log-in" class="button is-light is-rounded"><i class="fas fa-sign-in-alt mr-2"></i> Log in</router-link>
                        </template>
                        <template v-else>
                            <router-link to="/dashboard/my-account" class="button is-info is-rounded"><i class="fas fa-user mr-2"></i> My Account</router-link>
                            <a href="https://docs.refrme.io" class="button is-link is-rounded" target="_blank"><i class="fas fa-question-circle mr-2"></i> Help Center</a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
        return {
            isOpen: false,
            dropdowns: {
                offerDropdown: false,
                teamDropdown: false,
                referralDropdown: false
            }
        };
    },
    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen;
        },
        toggleDropdown(dropdown) {
            this.dropdowns[dropdown] = !this.dropdowns[dropdown];
        }
    }
}
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.navbar-item, .navbar-link {
    font-size: 1rem; /* Ensure uniform font size */
}

.container {
    margin-top: 20px;
}

.box {
    border-radius: 8px;
    transition: transform 0.3s;
}

.box:hover {
    transform: scale(1.03);
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.button.is-light.is-rounded {
    background-color: #f5f5f5;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-light.is-rounded:hover {
    background-color: #e2e2e2;
}

.button.is-info.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-info.is-rounded:hover {
    background-color: #1a8cc2;
}

.button.is-danger.is-rounded {
    background-color: #ff3860;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-danger.is-rounded:hover {
    background-color: #ff1744;
}

.label {
    font-size: 1.2em;
    color: #363636;
}

.mr-2 {
    margin-right: 8px;
}

.navbar-item.has-dropdown .navbar-link {
    cursor: pointer;
}

.navbar-dropdown.is-hidden {
    display: none;
}
</style>






