<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-users mr-2"></i> Customers</h1>

                <router-link class="button is-primary is-rounded is-medium animated-button"
                    to="/dashboard/customers/add"
                ><i class="fas fa-user-plus mr-2"></i> Add customer</router-link>

                <hr>

                <form @submit.prevent="getClients" class="form column is-12">
                    <div class="columns is-multiline">
                        <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                            <div class="field">
                                <div class="control">
                                    <input type="text" class="input is-rounded" v-model="query" placeholder="Search by name">
                                </div>
                            </div>
                        </div>
                        <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                            <div class="field">
                                <div class="control">
                                    <div class="select is-rounded is-fullwidth">
                                        <select v-model="statusFilter">
                                            <option value="">All Statuses</option>
                                            <option value="new">New</option>
                                            <option value="contacted">Contacted</option>
                                            <option value="scheduled">Scheduled</option>
                                            <option value="active">Active</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                            <div class="field">
                                <div class="control">
                                    <div class="select is-rounded is-fullwidth">
                                        <select v-model="sortField">
                                            <option value="">Sort By</option>
                                            <option value="created_at">Created On</option>
                                            <option value="modified_at">Modified At</option>
                                            <option value="last_contacted">Last Contacted</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                            <div class="field">
                                <div class="control">
                                    <div class="select is-rounded is-fullwidth">
                                        <select v-model="sortOrder">
                                            <option value="asc">Ascending</option>
                                            <option value="desc">Descending</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-12-mobile is-fullwidth mb-1">
                            <div class="field">
                                <div class="control">
                                    <button class="button is-primary is-rounded is-fullwidth animated-button" @click="getClients">
                                        <i class="fas fa-search mr-2"></i> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="column is-12">
                <template v-if="sortedClients.length">
                    <table class="table is-fullwidth is-striped is-hoverable animated-table rounded-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="client in sortedClients" v-bind:key="client.id" class="animated-row">
                                <td>{{ client.name }}</td>
                                <td>{{ client.status }}</td>
                                <td>
                                    <router-link class="button is-info is-rounded" :to="{ name: 'Client', params: { id: client.id }}" tag="button">
                                        <i class="fas fa-info-circle"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="buttons is-centered mt-4">
                        <button class="button is-light is-rounded" @click="goToPreviousPage()" v-if="showPreviousButton">
                            <i class="fas fa-arrow-left mr-2"></i> Previous
                        </button>
                        <button class="button is-light is-rounded" @click="goToNextPage()" v-if="showNextButton">
                            Next <i class="fas fa-arrow-right ml-2"></i>
                        </button>
                    </div>
                </template>

                <template v-else>
                    <p class="has-text-centered">You don't have any clients yet...</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Clients',
    data() {
        return {
            clients: [],
            showNextButton: false,
            showPreviousButton: false,
            currentPage: 1,
            query: '',
            statusFilter: '',
            sortField: '',
            sortOrder: 'asc',
            num_clients: 0
        }
    },
    mounted() {
        this.getClients()
    },
    computed: {
        sortedClients() {
            return this.clients
        }
    },
    methods: {
        goToNextPage() {
            this.currentPage += 1
            this.getClients()
        },
        goToPreviousPage() {
            this.currentPage -= 1
            this.getClients()
        },
        async getClients() {
            this.$store.commit('setIsLoading', true)

            this.showNextButton = false
            this.showPreviousButton = false

            let queryString = `/api/v1/clients/?page=${this.currentPage}`
            if (this.query) {
                queryString += `&search=${this.query}`
            }
            if (this.statusFilter) {
                queryString += `&status=${this.statusFilter}`
            }
            if (this.sortField) {
                queryString += `&ordering=${this.sortOrder === 'asc' ? '' : '-'}${this.sortField}`
            }

            await axios
                .get(queryString)
                .then(response => {
                    this.clients = response.data.results
                    this.num_clients = response.data.count

                    if (response.data.next) {
                        this.showNextButton = true
                    }

                    if (response.data.previous) {
                        this.showPreviousButton = true
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.animated-button {
    transition: transform 0.3s, background-color 0.3s;
}

.animated-button:hover {
    transform: scale(1.05);
    background-color: #006bb3;
}

.table.animated-table {
    animation: fadeIn 0.5s;
}

.animated-row {
    animation: slideInUp 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.rounded-table {
    border-radius: 10px; /* Adjust the radius as needed */
    overflow: hidden; /* Ensure the table contents respect the border radius */
}

.rounded-table thead th {
    border-top-left-radius: 10px; /* Round the top left corner */
    border-top-right-radius: 10px; /* Round the top right corner */
}

.rounded-table thead th:last-child {
    border-top-right-radius: 10px; /* Round the top right corner */
}

.rounded-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px; /* Round the bottom left corner */
}

.rounded-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px; /* Round the bottom right corner */
}

.button.is-active {
    background-color: #48c774;
    color: white;
}

.button.rounded-button {
    border-radius: 20px;
    font-size: 1.2em; /* Increase font size */
    padding: 10px 20px; /* Add padding */
}

.field.mb-5 {
    margin-bottom: 20px;
}

.button.mx-2 {
    margin-left: 10px;
    margin-right: 10px;
}

/* Adjust spacing between filters on mobile */
@media (max-width: 768px) {
    .form .column {
        margin-bottom: -10px !important; /* Ensure all form columns have reduced margin */
    }
    .form .field {
        margin-bottom: -10px !important; /* Ensure all form fields have reduced margin */
    }
}

</style>

