<template>
    <div class="container">
        <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                <h1 class="title has-text-centered">
                    <i class="fas fa-sign-in-alt mr-2"></i> Log in
                </h1>
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label class="label">Email</label>
                        <div class="control has-icons-left">
                            <input type="email" name="email" class="input is-rounded" v-model="username">
                            <span class="icon is-left">
                                <i class="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
    
                    <div class="field">
                        <label class="label">Password</label>
                        <div class="control has-icons-left">
                            <input type="password" name="password" class="input is-rounded" v-model="password">
                            <span class="icon is-left">
                                <i class="fas fa-lock"></i>
                            </span>
                        </div>
                    </div>
    
                    <div class="notification is-danger is-rounded" v-if="errors.length">
                        <p v-for="error in errors" :key="error">{{ error }}</p>
                    </div>
    
                    <div class="field">
                        <div class="control">
                            <button class="button is-primary is-rounded is-fullwidth">
                                <i class="fas fa-sign-in-alt mr-2"></i> Submit
                            </button>
                        </div>
                    </div>
    
                    <div class="field has-text-centered">
                        <button class="button is-text" @click="goToResetPassword">
                            Forgot Password?
                        </button>
                    </div>
                    <div class="field has-text-centered">
                        <button class="button is-text" @click="goToSignUp">
                            Need an Account? Sign Up Here
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'LogIn',
    data() {
        return {
            username: '',
            password: '',
            errors: []
        }
    },
    methods: {
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            axios.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')

            const formData = {
                username: this.username,
                password: this.password
            }

            try {
                const response = await axios.post('/api/v1/token/login/', formData)
                const token = response.data.auth_token

                this.$store.commit('setToken', token)
                axios.defaults.headers.common['Authorization'] = 'Token ' + token
                localStorage.setItem('token', token)

                const userResponse = await axios.get('/api/v1/users/me')
                this.$store.commit('setUser', { id: userResponse.data.id, username: userResponse.data.username })
                localStorage.setItem('username', userResponse.data.username)
                localStorage.setItem('userid', userResponse.data.id)

                const teamResponse = await axios.get('/api/v1/teams/get_my_team/')
                if (!teamResponse.data.id) {
                    this.$router.push('/dashboard/add-team')
                } else {
                    this.$store.commit('setTeam', {
                        id: teamResponse.data.id,
                        name: teamResponse.data.name,
                        plan: teamResponse.data.plan.name,
                        max_leads: teamResponse.data.plan.max_leads,
                        max_clients: teamResponse.data.plan.max_clients,
                        max_members: teamResponse.data.plan.max_members
                    })
                    
                    // Check subscription status on login
                    const subscriptionResponse = await axios.post('/api/v1/stripe/check_subscription/', { teamId: teamResponse.data.id })
                    if (subscriptionResponse.data.error) {
                        this.errors.push(subscriptionResponse.data.error)
                    } else {
                        const subscriptionStatus = subscriptionResponse.data.plan_status;
                        this.$store.commit('setSubscriptionStatus', subscriptionStatus)
                        // Update team with subscription status
                        this.$store.commit('setTeam', {
                            id: teamResponse.data.id,
                            name: teamResponse.data.name,
                            plan_status: subscriptionResponse.data.plan_status,
                            plan_end_date: subscriptionResponse.data.plan_end_date,
                            plan: subscriptionResponse.data.plan.name,
                            max_leads: teamResponse.data.plan.max_leads,
                            max_clients: teamResponse.data.plan.max_clients,
                            max_members: teamResponse.data.plan.max_members + subscriptionResponse.data.additional_members
                        })
                    }

                    this.$posthog.capture('User Login', {
                        'user_name': this.username,
                        'team': teamResponse.data.name,
                        'admin': teamResponse.data.created_by.username
                    });

                    this.$posthog.identify(this.username,{ // Replace "distinct_id" with your user's unique identifier
                        email: this.username, // optional: set additional person properties
                        id: this.userid,
                        team: teamResponse.data.name
                    })

                    const redirect = this.$route.query.redirect || '/dashboard/'
                    this.$router.push(redirect)
                }
            } catch (error) {
                if (error.response) {
                    for (const property in error.response.data) {
                        this.errors.push(`${property}: ${error.response.data[property]}`)
                    }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!')
                }
            } finally {
                this.$store.commit('setIsLoading', false)
            }
        },
        goToResetPassword() {
            this.$router.push('/reset-password')
        },
        goToSignUp() {
            this.$router.push('/sign-up')
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 50px;
}

.title {
    margin-bottom: 30px;
}

.field {
    margin-bottom: 20px;
}

.button.is-primary.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
}

.button.is-primary.is-rounded:hover {
    background-color: #1a8cc2;
}

.icon.is-left {
    left: 10px;
}

.button.is-text {
    background: none;
    border: none;
    color: #209cee;
    font-size: 1em;
    cursor: pointer;
}

.button.is-text:hover {
    text-decoration: underline;
}
</style>



