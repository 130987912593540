<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12" v-if="team && team.name">
                <h1 class="title"><i class="fas fa-gift"></i> {{ team.name }} Referral Incentives</h1>
                <div>
                    <h2 class="subtitle"><i class="fas fa-star"></i> Active Incentive</h2>
                </div>
                <div class="box" v-if="activeIncentive">
                    <div class="content">
                        <div class="incentive-info">
                            <h2 class="subtitle"><i class="fas fa-gift"></i> {{ activeIncentive.name }}</h2>
                            <p><strong>Offer:</strong> {{ activeIncentive.offer }}</p>
                        </div>
                        <div class="tags-container">
                            <div class="tag is-info is-light">
                                Referrals: {{ activeIncentive.leads_count }}
                            </div>
                            <div class="tag is-success is-light">
                                Total Estimated Value: ${{ activeIncentive.total_estimated_value }}
                            </div>
                        </div>
                    </div>
                    <div class="buttons" v-if="team.created_by && team.created_by.id === parseInt($store.state.user.id)">
                        <button class="button is-info is-rounded" @click="openEditModal(activeIncentive, true)"><i class="fas fa-edit"></i> Edit</button>
                    </div>
                </div>

                <button class="button is-primary is-rounded mb-4" @click="openCreateModal"><i class="fas fa-plus"></i> Create Incentive</button>

                <div class="columns is-multiline">
                    <div class="column is-12" v-for="incentive in otherIncentives" :key="incentive.id">
                        <div class="box">
                            <div class="content">
                                <div class="incentive-info">
                                    <h2 class="subtitle"><i class="fas fa-gift"></i> {{ incentive.name }}</h2>
                                    <p><strong>Offer:</strong> {{ incentive.offer }}</p>
                                </div>
                                <div class="tags-container">
                                    <div class="tag is-info is-light">
                                        Referrals: {{ incentive.leads_count }}
                                    </div>
                                    <div class="tag is-success is-light">
                                        Total Estimated Value: ${{ incentive.total_estimated_value }}
                                    </div>
                                </div>
                            </div>
                            <div class="buttons" v-if="team.created_by && team.created_by.id === parseInt($store.state.user.id)">
                                <button class="button is-info is-rounded" @click="openEditModal(incentive, false)"><i class="fas fa-edit"></i> Edit</button>
                                <button class="button is-success is-rounded" @click="openActiveModal(incentive)"><i class="fas fa-check"></i> Set Active</button>
                                <button class="button is-danger is-rounded" @click="confirmDelete(incentive)" v-if="!incentive.is_active"><i class="fas fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Create Incentive Modal -->
        <div class="modal" :class="{'is-active': showCreateModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Create Incentive</p>
                    <button class="delete" aria-label="close" @click="closeCreateModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label"><strong><i class="fas fa-tag"></i> Incentive Name</strong></label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="newIncentive.name">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><strong><i class="fas fa-hand-holding-usd"></i> Incentive Offer</strong></label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="newIncentive.offer">
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="createIncentive">Create</button>
                    <button class="button" @click="closeCreateModal">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Edit Incentive Modal -->
        <div class="modal" :class="{'is-active': showEditModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Edit Incentive</p>
                    <button class="delete" aria-label="close" @click="closeEditModal"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label"><strong><i class="fas fa-tag"></i> Incentive Name</strong></label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="editIncentive.name">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><strong><i class="fas fa-hand-holding-usd"></i> Incentive Offer</strong></label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="editIncentive.offer">
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="saveIncentive">Save</button>
                    <button class="button" @click="closeEditModal">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Set Active Incentive Modal -->
        <div class="modal" :class="{'is-active': showActiveModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Set Active Incentive</p>
                    <button class="delete" aria-label="close" @click="closeActiveModal"></button>
                </header>
                <section class="modal-card-body">
                    <p>Are you sure you want to set this incentive as active?</p>
                    <p><strong>Name:</strong> {{ activeIncentiveTemp.name }}</p>
                    <p><strong>Offer:</strong> {{ activeIncentiveTemp.offer }}</p>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="setActiveIncentiveConfirmed">Set Active</button>
                    <button class="button" @click="closeActiveModal">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Confirmation Modal -->
        <div class="modal" :class="{'is-active': showModal}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Confirm Delete</p>
                    <button class="delete" aria-label="close" @click="showModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <p>Are you sure you want to remove this incentive?</p>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="deleteIncentive">Delete</button>
                    <button class="button" @click="showModal = false">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
    name: 'Incentive',
    data() {
        return {
            team: null,
            activeIncentive: null,
            otherIncentives: [],
            showModal: false,
            showCreateModal: false,
            showEditModal: false,
            showActiveModal: false,
            incentiveToDelete: null,
            editIncentive: {},
            newIncentive: {
                name: '',
                offer: ''
            },
            activeIncentiveTemp: {},
            isEditingActiveIncentive: false,
        }
    },
    mounted() {
        this.getTeam();
    },
    methods: {
        async getTeam() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data;
                    this.getIncentives();
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async getIncentives() {
            this.$store.commit('setIsLoading', true);

            await axios
                .post('/api/v1/teams/get_team_incentives/')
                .then(response => {
                    const incentives = response.data;
                    this.activeIncentive = incentives.find(incentive => incentive.is_active);
                    this.otherIncentives = incentives.filter(incentive => !incentive.is_active);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        openCreateModal() {
            this.showCreateModal = true;
        },
        closeCreateModal() {
            this.showCreateModal = false;
            this.newIncentive = { name: '', offer: '' };
        },
        async createIncentive() {
            this.$store.commit('setIsLoading', true);

            await axios.post('/api/v1/teams/update_incentive/', {
                name: this.newIncentive.name,
                offer: this.newIncentive.offer
            }).then(response => {
                toast({
                    message: 'Incentive created successfully',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                });
                this.getIncentives();
            }).catch(error => {
                console.log(error);
            });

            this.showCreateModal = false;
            this.$store.commit('setIsLoading', false);
        },
        openEditModal(incentive, isActive) {
            this.editIncentive = { ...incentive };
            this.isEditingActiveIncentive = isActive;
            this.showEditModal = true;
        },
        closeEditModal() {
            this.showEditModal = false;
            this.editIncentive = {};
            this.isEditingActiveIncentive = false;
        },
        async saveIncentive() {
            this.$store.commit('setIsLoading', true);

            await axios.post('/api/v1/teams/update_incentive/', {
                selected_incentive: this.editIncentive.id,
                name: this.editIncentive.name,
                offer: this.editIncentive.offer,
                is_active: this.isEditingActiveIncentive
            }).then(response => {
                toast({
                    message: 'Incentive updated successfully',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                });
                this.getIncentives();
            }).catch(error => {
                console.log(error);
            });

            this.showEditModal = false;
            this.$store.commit('setIsLoading', false);
            this.isEditingActiveIncentive = false;
        },
        openActiveModal(incentive) {
            this.activeIncentiveTemp = { ...incentive };
            this.showActiveModal = true;
        },
        closeActiveModal() {
            this.showActiveModal = false;
            this.activeIncentiveTemp = {};
        },
        async setActiveIncentiveConfirmed() {
            this.$store.commit('setIsLoading', true);

            await axios.post('/api/v1/teams/update_incentive/', {
                selected_incentive: this.activeIncentiveTemp.id,
                is_active: true,
                name: this.activeIncentiveTemp.name,
                offer: this.activeIncentiveTemp.offer
            }).then(response => {
                toast({
                    message: 'Incentive set as active',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                });
                this.getIncentives();
            }).catch(error => {
                console.log(error);
            });

            this.showActiveModal = false;
            this.$store.commit('setIsLoading', false);
        },
        confirmDelete(incentive) {
            this.incentiveToDelete = incentive;
            this.showModal = true;
        },
        async deleteIncentive() {
            if (this.incentiveToDelete) {
                const data = {
                    incentive_id: this.incentiveToDelete.id
                };

                await axios.post('/api/v1/teams/delete_incentive/', data)
                    .then(response => {
                        toast({
                            message: 'Incentive deleted',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        });
                        this.getIncentives();
                        this.showModal = false;
                        this.incentiveToDelete = null;
                    })
                    .catch(error => {
                        console.log(error);
                        this.showModal = false;
                    });
            }
        }
    }
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.field {
    padding-bottom: 10px;
}

.button.is-primary {
    background-color: #00d1b2;
    border-color: transparent;
    color: white;
}

.button.is-primary:hover {
    background-color: #00b89c;
    color: white;
}

.animated-button {
    transition: transform 0.3s, background-color 0.3s;
}

.animated-button:hover {
    transform: scale(1.05);
    background-color: #006bb3;
}

.label {
    font-size: 1.2em;
    color: #363636;
}

.tags-container {
    margin-top: 10px;
    margin-left: auto;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.incentive-info {
    flex: 1;
}
</style>

