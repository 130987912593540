<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Add member</h1>
            </div>

            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" name="email" class="input is-rounded" v-model="username" required>
                        </div>
                    </div>

                    <!-- <div class="field">
                        <label>First name</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="user.first_name">
                        </div>
                    </div>

                    <div class="field">
                        <label>Last name</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="user.last_name">
                        </div>
                    </div> -->

                    <div class="field">
                        <label>Password</label>
                        <div class="control">
                            <input type="password" name="password1" class="input is-rounded" v-model="password1">
                        </div>
                    </div>

                    <div class="field">
                        <label>Repeat password</label>
                        <div class="control">
                            <input type="password" name="password2" class="input is-rounded" v-model="password2">
                        </div>
                        <div>
                            <h4> * Your password can’t be too similar to your other personal information.</h4>
                            <h4> * Your password must contain at least 8 characters.</h4>
                            <h4> * Your password can’t be a commonly used password.</h4>
                            <h4> * Your password can’t be entirely numeric.</h4>
                        </div>
                    </div>

                    <div class="notification is-danger is-rounded" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <template v-if="team.created_by.id === parseInt($store.state.user.id)">
                        <div class="field">
                            <div class="control">
                                <button class="button is-primary is-rounded"
                                to="/dashboard/team"
                                v-if="this.$store.state.team.max_members > team.members.length"
                                    >Add Member</button>

                                <div
                                    class="button is-light is-rounded"
                                    v-else
                                >
                                    Max Members Reached
                                </div>
                            </div>
                        </div>
                    </template>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import {toast} from 'bulma-toast'

export default {
    name: 'AddMember',
    data() {
        return {
            username: '',
            first_name: '',
            last_name: '',
            password1: '',
            password2: '',
            errors: [],
            team: {
                members: [],
                created_by: {}
            },
            user: {}
        }
    },
    mounted() {
        this.getTeam()
    },
    methods: {
        async getTeam() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        },
        async submitForm() {
            this.errors = []

            if (this.username === '') {
                this.errors.push('The username is missing')
            }

            // if (this.user.first_name === '') {
            //         this.errors.push('The first name is missing')
            //     }

            // if (this.user.last_name === '') {
            //     this.errors.push('The last name is missing')
            // }

            if (this.password1 === '') {
                this.errors.push('The password is too short')
            }

            if (this.password1 !== this.password2) {
                this.errors.push('The password are not matching')
            }

            if (!this.errors.length) {
                this.$store.commit('setIsLoading', true)

                const formData = {
                    username: this.username,
                    password: this.password1         
                }

                await axios
                    .post('/api/v1/users/', formData)
                    .then(response => {
                        toast({
                            message: 'The member was added',
                            type: 'is-primary is-rounded',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                        
                        // const newUserId = response.data.id

                        // axios
                        //     .put(`/api/v1/teams/member/${newUserID}/`, this.user)
                        //     .then(response => {
                        //         toast({
                        //             message: 'The user was updated',
                        //             type: 'is-primary is-rounded',
                        //             dismissible: true,
                        //             pauseOnHover: true,
                        //             duration: 2000,
                        //             position: 'bottom-right',
                        //         })
                        //     })
                        //     .catch(error => {
                        //         console.log(error)
                        //     })

                    this.$store.commit('setIsLoading', false)

                        const emailData = {'email': this.username}

                        axios
                            .post('/api/v1/teams/add_member/', emailData)
                            .then(response => {
                                this.$router.push({'name': 'Team'})
                            })
                            .catch(error => {
                                if (error.response) {
                                    for (const property in error.response.data) {
                                        this.errors.push(`${property}: ${error.response.data[property]}`)
                                    }
                                } else if (error.message) {
                                    this.errors.push('Something went wrong. Please try again!')
                                }
                            })
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })

                this.$store.commit('setIsLoading', false)
                this.$router.push('/dashboard/team')
            }
        }
    }
}
</script>