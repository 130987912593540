<template>
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <h1 class="title has-text-centered">
            <i class="fas fa-key mr-2"></i> Set New Password
          </h1>
          <form @submit.prevent="submitForm">
            <div class="field">
              <label class="label">New Password</label>
              <div class="control has-icons-left">
                <input type="password" name="password" class="input is-rounded" v-model="password" />
                <span class="icon is-left">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
            </div>
  
            <div class="field">
              <label class="label">Confirm New Password</label>
              <div class="control has-icons-left">
                <input type="password" name="re_password" class="input is-rounded" v-model="re_password" />
                <span class="icon is-left">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
            </div>
  
            <div class="notification is-danger is-rounded" v-if="errors.length">
              <p v-for="error in errors" :key="error">{{ error }}</p>
            </div>
  
            <div class="field">
              <div class="control">
                <button class="button is-primary is-rounded is-fullwidth">
                  <i class="fas fa-paper-plane mr-2"></i> Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  
  export default {
    name: 'PasswordResetConfirm',
    data() {
      return {
        password: '',
        re_password: '',
        uid: '',
        token: '',
        errors: []
      }
    },
    methods: {
      async submitForm() {
        this.errors = []
  
        if (this.password === '' || this.re_password === '') {
          this.errors.push('Both password fields are required')
          return
        }
  
        if (this.password !== this.re_password) {
          this.errors.push('Passwords do not match')
          return
        }
  
        try {
          await axios.post('/api/v1/users/reset_password_confirm/', {
            uid: this.uid,
            token: this.token,
            new_password: this.password,
            re_new_password: this.re_password
          })
          toast({
            message: 'Password reset successful.',
            type: 'is-primary is-rounded',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
          this.$router.push('/log-in')
        } catch (error) {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`)
            }
          } else if (error.message) {
            this.errors.push('Something went wrong. Please try again!')
          }
        }
      }
    },
    created() {
      this.uid = this.$route.params.uid
      this.token = this.$route.params.token
    }
  }
  </script>
  
  <style lang="scss">
  @import "~@fortawesome/fontawesome-free/css/all.min.css";
  
  .container {
    margin-top: 50px;
  }
  
  .title {
    margin-bottom: 30px;
  }
  
  .field {
    margin-bottom: 20px;
  }
  
  .button.is-primary.is-rounded {
    background-color: #209cee;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
  }
  
  .button.is-primary.is-rounded:hover {
    background-color: #1a8cc2;
  }
  
  .icon.is-left {
    left: 10px;
  }
  </style>
  
  

