<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Add referral</h1>
            </div>

            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Name/Company</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="company" required>
                        </div>
                    </div>

                    <div class="field">
                        <label>Address</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="contact_person">
                        </div>
                    </div>

                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" class="input is-rounded" v-model="email" required>
                        </div>
                    </div>

                    <div class="field">
                        <label>Phone</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="phone" required>
                        </div>
                    </div>

                    <div class="field">
                        <label>How can we help?</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="website">
                        </div>
                    </div>

                    <div class="field">
                        <label>Confidence</label>
                        <div class="control">
                            <input type="number" class="input is-rounded" v-model="confidence">
                        </div>
                    </div>

                    <div class="field">
                        <label>Estimated value</label>
                        <div class="control">
                            <input type="number" class="input is-rounded" v-model="estimated_value">
                        </div>
                    </div>

                    <div class="field">
                        <label>Status</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="status">
                                    <<option value="new">New</option>
                                    <option value="contacted">Contacted</option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="closed">Closed</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label>Priority</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="priority">
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label>Referred by</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="reference_by">
                                    <option value="" selected>Select customer</option>
                                    <option
                                        v-for="member in clients"
                                        v-bind:key="member.id"
                                        v-bind:value="member.id"
                                    >
                                        {{ member.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-primary is-rounded">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    import { toast } from 'bulma-toast'

    export default {
        name: 'AddLead',
        data() {
            return {
                team: {
                    members: [],
                    clients: []
                },
                clients:{},
                company: '',
                contact_person: '',
                email: '',
                phone: '',
                estimated_value: 0,
                confidence: 0,
                website: '',
                status: 'new',
                priority: 'medium',
                reference_by: ''
                
            }
        },
        mounted() {
            this.getTeam(),
            this.getClients()
        },
        methods: {
            async submitForm() {
                this.$store.commit('setIsLoading', true)

                const lead = {
                    company: this.company,
                    contact_person: this.contact_person,
                    email: this.email,
                    phone: this.phone,
                    website: this.website,
                    estimated_value: this.estimated_value,
                    confidence: this.confidence,
                    status: this.status,
                    priority: this.priority,
                    reference_by: this.reference_by
                }

                await axios
                    .post('/api/v1/leads/', lead)
                    .then(response => {
                        toast({
                            message: 'The lead was added',
                            type: 'is-primary is-rounded',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })

                        this.$router.push('/dashboard/referrals')
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
            async getTeam() {
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/teams/get_my_team/')
                    .then(response => {
                        this.team = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
                
                this.$store.commit('setIsLoading', false)
            },
            async getClients() {
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/clients/all_clients/')
                    .then(response => {
                        this.clients = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
        }
        }
    }
</script>