<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-paper-plane mr-2"></i> My Referrals</h1>
            </div>
            <form @submit.prevent="getMyLeads" class="form column is-12">
                <div class="columns is-multiline">
                    <!-- Existing Filters -->
                    <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                        <div class="field">
                            <div class="control">
                                <input type="text" class="input is-rounded" v-model="query" placeholder="Search by name">
                            </div>
                        </div>
                    </div>
                    <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                        <div class="field">
                            <div class="control">
                                <div class="select is-rounded is-fullwidth">
                                    <select v-model="statusFilter">
                                        <option value="">All Statuses</option>
                                        <option value="new">New</option>
                                        <option value="contacted">Contacted</option>
                                        <option value="scheduled">Scheduled</option>
                                        <option value="closed">Closed</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                        <div class="field">
                            <div class="control">
                                <div class="select is-rounded is-fullwidth">
                                    <select v-model="sortField">
                                        <option value="">Sort By</option>
                                        <option value="created_at">Referred On</option>
                                        <option value="modified_at">Modified At</option>
                                        <option value="last_contacted">Last Contacted</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div> <!-- Close missing div here -->
                    <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
                        <div class="field">
                            <div class="control">
                                <div class="select is-rounded is-fullwidth">
                                    <select v-model="sortOrder">
                                        <option value="asc">Ascending</option>
                                        <option value="desc">Descending</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12-mobile is-fullwidth mb-1">
                        <div class="field">
                            <div class="control">
                                <button class="button is-primary is-rounded is-fullwidth animated-button" @click="searchLeads">
                                    <i class="fas fa-search mr-2"></i> Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="column is-12">
                <table class="table is-fullwidth is-striped is-hoverable animated-table rounded-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Assigned to</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="lead in sortedMyLeads" v-bind:key="lead.id" class="animated-row">
                            <template v-if="lead.assigned_to !== null && lead.assigned_to.username === this.$store.state.user.username">
                                <td>{{ lead.company }}</td>
                                <td>
                                    <template v-if="lead.assigned_to">{{ lead.assigned_to.first_name }} {{ lead.assigned_to.last_name }} {{ lead.assigned_to.username }}</template>
                                </td>
                                <td>
                                    <router-link class="button is-info is-rounded" :to="{ name: 'Lead', params: { id: lead.id }}">
                                        <i class="fas fa-info-circle"></i> 
                                    </router-link>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>

                <div class="buttons is-centered mt-4">
                    <button class="button is-light is-rounded" @click="goToPreviousPageMyLeads" v-if="showPreviousButtonMyLeads">
                        <i class="fas fa-arrow-left mr-2"></i> Previous
                    </button>
                    <button class="button is-light is-rounded" @click="goToNextPageMyLeads" v-if="showNextButtonMyLeads">
                        Next <i class="fas fa-arrow-right ml-2"></i>
                    </button>
                </div>
            </div>

            <template v-if="team.created_by.id === parseInt($store.state.user.id)">
                <div class="column is-12">
                    <h1 class="title"><i class="fas fa-users mr-2"></i> Team Referrals</h1>

                    <router-link class="button is-primary is-rounded animated-button" to="/dashboard/referrals/add">
                        <i class="fas fa-user-plus mr-2"></i> Add referral
                    </router-link>

                    <hr>
                </div>

                <div class="column is-12">
                    <table class="table is-fullwidth is-striped is-hoverable animated-table rounded-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Assigned to</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="lead in sortedTeamLeads" v-bind:key="lead.id" class="animated-row">
                                <td>{{ lead.company }}</td>
                                <td>
                                    <template v-if="lead.assigned_to">{{ lead.assigned_to.first_name }} {{ lead.assigned_to.last_name }} {{ lead.assigned_to.username }}</template>
                                </td>
                                <td>
                                    <router-link class="button is-info is-rounded" :to="{ name: 'Lead', params: { id: lead.id }}">
                                        <i class="fas fa-info-circle"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="buttons is-centered mt-4">
                        <button class="button is-light is-rounded" @click="goToPreviousPageTeamLeads" v-if="showPreviousButtonTeamLeads">
                            <i class="fas fa-arrow-left mr-2"></i> Previous
                        </button>
                        <button class="button is-light is-rounded" @click="goToNextPageTeamLeads" v-if="showNextButtonTeamLeads">
                            Next <i class="fas fa-arrow-right ml-2"></i>
                        </button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Leads',
    data() {
        return {
            myLeads: [],
            showNextButtonMyLeads: false,
            showPreviousButtonMyLeads: false,
            currentPageMyLeads: 1,
            teamLeads: [],
            showNextButtonTeamLeads: false,
            showPreviousButtonTeamLeads: false,
            currentPageTeamLeads: 1,
            query: '',
            statusFilter: '',
            sortField: '',
            sortOrder: 'asc',
            num_my_leads: 0,
            num_team_leads: 0,
            team: {
                members: [],
                created_by: {}
            }
        }
    },
    mounted() {
        this.getMyLeads()
        this.getTeamLeads()
        this.getTeam()
    },
    computed: {
        sortedMyLeads() {
            return this.myLeads
        },
        sortedTeamLeads() {
            return this.teamLeads
        }
    },
    methods: {
        searchLeads() {
            this.getMyLeads()
            this.getTeamLeads()

        },
        goToNextPageMyLeads() {
            this.currentPageMyLeads += 1
            this.getMyLeads()
        },
        goToPreviousPageMyLeads() {
            this.currentPageMyLeads -= 1
            this.getMyLeads()
        },
        goToNextPageTeamLeads() {
            this.currentPageTeamLeads += 1
            this.getTeamLeads()
        },
        goToPreviousPageTeamLeads() {
            this.currentPageTeamLeads -= 1
            this.getTeamLeads()
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/teams/get_my_team/')
                .then(response => {
                    this.team = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async getMyLeads() {
            this.$store.commit('setIsLoading', true)

            this.showNextButtonMyLeads = false
            this.showPreviousButtonMyLeads = false

            let queryString = `/api/v1/leads/?page=${this.currentPageMyLeads}`
            if (this.query) {
                queryString += `&search=${this.query}`
            }
            if (this.statusFilter) {
                queryString += `&status=${this.statusFilter}`
            }
            if (this.sortField) {
                queryString += `&ordering=${this.sortOrder === 'asc' ? '' : '-'}${this.sortField}`
            }
            queryString += `&assigned_to=${this.$store.state.user.id}`

            await axios
                .get(queryString)
                .then(response => {
                    this.myLeads = response.data.results
                    this.num_my_leads = response.data.count

                    if (response.data.next) {
                        this.showNextButtonMyLeads = true
                    }

                    if (response.data.previous) {
                        this.showPreviousButtonMyLeads = true
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async getTeamLeads() {
            this.$store.commit('setIsLoading', true)

            this.showNextButtonTeamLeads = false
            this.showPreviousButtonTeamLeads = false

            let queryString = `/api/v1/leads/?page=${this.currentPageTeamLeads}`
            if (this.query) {
                queryString += `&search=${this.query}`
            }
            if (this.statusFilter) {
                queryString += `&status=${this.statusFilter}`
            }
            if (this.sortField) {
                queryString += `&ordering=${this.sortOrder === 'asc' ? '' : '-'}${this.sortField}`
            }

            await axios
                .get(queryString)
                .then(response => {
                    this.teamLeads = response.data.results
                    this.num_team_leads = response.data.count

                    if (response.data.next) {
                        this.showNextButtonTeamLeads = true
                    }

                    if (response.data.previous) {
                        this.showPreviousButtonTeamLeads = true
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.animated-button {
    transition: transform 0.3s, background-color 0.3s;
}

.animated-button:hover {
    transform: scale(1.05);
    background-color: #006bb3;
}

.table.animated-table {
    animation: fadeIn 0.5s;
}

.rounded-table {
    border-radius: 10px; /* Adjust the radius as needed */
    overflow: hidden; /* Ensure the table contents respect the border radius */
}

.rounded-table thead th {
    border-top-left-radius: 10px; /* Round the top left corner */
    border-top-right-radius: 10px; /* Round the top right corner */
}

.rounded-table thead th:last-child {
    border-top-right-radius: 10px; /* Round the top right corner */
}

.rounded-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px; /* Round the bottom left corner */
}

.rounded-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px; /* Round the bottom right corner */
}

.animated-row {
    animation: slideInUp 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.button.is-active {
    background-color: #48c774;
    color: white;
}

.button.rounded-button {
    border-radius: 20px;
    font-size: 1.2em; /* Increase font size */
    padding: 10px 20px; /* Add padding */
}

.field.mb-5 {
    margin-bottom: 20px;
}

.button.mx-2 {
    margin-left: 10px;
    margin-right: 10px;
}

/* Adjust spacing between filters on mobile */
@media (max-width: 768px) {
    .form .column {
        margin-bottom: -10px !important; /* Ensure all form columns have reduced margin */
    }
    .form .field {
        margin-bottom: -10px !important; /* Ensure all form fields have reduced margin */
    }
}
</style>









