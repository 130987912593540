import { createApp, nextTick }  from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/custom.scss'; // Ensure this path is correct
import posthogPlugin from "./plugins/posthog";

axios.defaults.baseURL = 'https://api.refrme.io'
//axios.defaults.baseURL = 'http://127.0.0.1:8000'


//const app = createApp(App).use(store).use(router, axios).use(posthogPlugin).mount('#app')

const app = createApp(App);
app.use(store)
app.use(posthogPlugin)
.use(router, axios)
.mount('#app');

router.afterEach((to, from, failure) => {
    if (!failure) {
      nextTick(() => {
        app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
      });
    }
  });
