//./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      'phc_hNbO1n7JqG3o6Ong36ptN82mpTts7E31jUKNuoYhcBc',
      {
        api_host: 'https://us.i.posthog.com',
        //person_profiles: 'identified_only',
        capture_pageview: false
      }
    );
  },
};