<template>
    <div class="container">
        <div class="columns is-multiline">
            <template v-if="this.selected_incentive != null">
                <div class="notification is-info is-rounded">
                    <i class="fas fa-gift mr-2"></i> 
                    <span>Receive <strong>{{ this.selected_incentive.offer }}</strong> with every successful referral!</span>
                </div>
            </template>
            
            <div class="column is-12">
                <h1 class="title"><i class="fas fa-user-check mr-2"></i> Existing Customer</h1>
            </div>
            <div class="column is-12">
                <div class="field">
                    <label>Existing Customers</label>
                    <div class="control">
                        <v-select
                            :options="team.clients"
                            label="name"
                            placeholder="Type Name of Customer to Search"
                            v-model="existingCustomer"
                            :reduce="existingCustomer => existingCustomer.id"
                            :dropdown-should-open="dropdownShouldOpen"
                        ></v-select>
                    </div>
                </div>
                <div class="field">
                    <router-link :to="`/referrals/referral/${ref_code}/${existingCustomer}`" class="button is-primary is-rounded mt-6">
                        <i class="fas fa-plus-circle mr-2"></i> Add Referral
                    </router-link>
                </div>
            </div>

            <div class="column is-12">
                <h1 class="title"><i class="fas fa-user-plus mr-2"></i> New Customer</h1>
            </div>
            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Name</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="name" required>
                        </div>
                    </div>

                    <div class="field">
                        <label>Address</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="contact_person">
                        </div>
                    </div>

                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" class="input is-rounded" v-model="email" required>
                        </div>
                    </div>

                    <div class="field">
                        <label>Phone</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="phone" required>
                        </div>
                    </div>

                    <div class="field">
                        <label>How can we help?</label>
                        <div class="control">
                            <input type="text" class="input is-rounded" v-model="website">
                        </div>
                    </div>

                    <div class="field">
                        <label class="checkbox">
                            <input type="checkbox" v-model="addReferral">
                            Check to Add Referral
                        </label>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-primary is-rounded">
                                <i class="fas fa-paper-plane mr-2"></i> Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: 'AddClientWithReferral',
    data() {
        return {
            name: '',
            contact_person: '',
            email: '',
            phone: '',
            estimated_value: 0,
            website: '',
            addReferral: false,
            existingCustomer: null,
            ref_code: this.$route.params.ref_code,
            team: {},
            selected_incentive: null
        }
    },
    mounted() {
        this.getTeam()
    },
    components: {
        vSelect
    },
    methods: {
        dropdownShouldOpen(VueSelect) {
            if (this.existingCustomer !== null) {
                return VueSelect.open
            }

            return VueSelect.search.length !== 0 && VueSelect.open
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)

            const ref_code_dict = {
                ref_code: this.$route.params.ref_code
            }

            await axios
                .post('/api/v1/teams/get_my_team_with_referral/', ref_code_dict)
                .then(response => {
                    this.team = response.data
                    this.ref_code = this.$route.params.ref_code
                    this.selected_incentive = response.data.incentives[Object.keys(response.data.incentives)[0]];
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const client = {
                name: this.name,
                contact_person: this.contact_person,
                email: this.email,
                phone: this.phone,
                website: this.website,
                ref_code: this.$route.params.ref_code
            }

            await axios
                .post('/api/v1/clients/referral/add', client)
                .then(response => {
                    if (response.data.message === 'The client was added') {
                        toast({
                            message: 'The customer was added',
                            type: 'is-primary is-rounded',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                        if (this.addReferral) {
                            this.$router.push(`/referrals/referral/${client.ref_code}/${response.data.client_id}`)
                        } else {
                            this.$router.push('/customer/Thankyou')
                        }
                    } else {
                        toast({
                            message: response.data.error,
                            type: 'is-warning',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    }
                })
                .catch(error => {
                    toast({
                        message: error.response ? error.response.data.message : 'An error occurred',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.container {
    margin-top: 20px;
}

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    padding: 10px 15px;
    text-align: center;
    margin-bottom: 20px;
}

.notification i {
    flex-shrink: 0; /* Prevent the icon from shrinking */
}

.notification span {
    flex: 1;
    text-align: left; /* Align text to the left for better readability */
}

.button.is-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
}

.mr-2 {
    margin-right: 10px;
}
</style>
