<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title"><i class="fas fa-paper-plane mr-2"></i> Team Referral Board</h1>
      </div>
      <form @submit.prevent="searchLeads" class="form column is-12">
        <div class="columns is-multiline">
          <!-- Existing Filters -->
          <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
            <div class="field">
              <div class="control">
                <input type="text" class="input is-rounded" v-model="query" placeholder="Search by name">
              </div>
            </div>
          </div>
          <!-- <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
            <div class="field">
              <div class="control">
                <div class="select is-rounded is-fullwidth">
                  <select v-model="statusFilter">
                    <option value="">All Statuses</option>
                    <option value="new">New</option>
                    <option value="contacted">Contacted</option>
                    <option value="scheduled">Scheduled</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              </div>
            </div>
          </div> -->
          <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
            <div class="field">
              <div class="control">
                <div class="select is-rounded is-fullwidth">
                  <select v-model="sortField">
                    <option value="">Sort By</option>
                    <option value="created_at">Referred On</option>
                    <option value="modified_at">Modified At</option>
                    <option value="last_contacted">Last Contacted</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12-mobile is-6-tablet is-3-desktop mb-1">
            <div class="field">
              <div class="control">
                <div class="select is-rounded is-fullwidth">
                  <select v-model="sortOrder">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12-mobile is-fullwidth mb-1">
            <div class="field">
              <div class="control">
                <button class="button is-primary is-rounded is-fullwidth animated-button">
                  <i class="fas fa-search mr-2"></i> Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <template v-if="team.created_by.id === parseInt($store.state.user.id)">
        <!-- <div class="column is-12">
          <h1 class="title"><i class="fas fa-users mr-2"></i> Team Referral Board</h1>

          <router-link class="button is-primary is-rounded animated-button" to="/dashboard/leads/add">
            <i class="fas fa-user-plus mr-2"></i> Add referral
          </router-link>

          <hr>
        </div> -->

        <div class="column is-12">
          <div class="kanban-board">
            <div class="kanban-column" v-for="status in statuses" :key="status">
              <h2 class="title is-4 status-label">{{ statusLabels[status] }}</h2>
              <draggable
              :list="filteredTeamLeadsByStatus(status)"
              group="statuses"
              @end="onEnd"
              :data-id="status"
              class="kanban-list"
              item-key="id"
            >
              <template #item="{element}">
                <div class="kanban-item" :key="element.id" :data-id="element.id">
                  <div class="card">
                    <header class="card-header">
                      <p class="card-header-title">{{ element.company }}</p>
                      <router-link class="button is-info is-rounded" :to="{ name: 'Lead', params: { id: element.id }}">
                        <i class="fas fa-info-circle"></i>
                      </router-link>
                    </header>
                    <div class="card-content">
                      <div class="content">
                        <div><strong>Assigned to:</strong> {{ element.assigned_to ? `${element.assigned_to.first_name} ${element.assigned_to.last_name}` : 'Unassigned' }}</div>
                        <div><strong>Referred on:</strong> {{ new Date(element.created_at).toDateString() }}</div>
                        <div><strong>Last Contacted:</strong> {{ new Date(element.last_contacted).toDateString() }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
              <div v-if="filteredTeamLeadsByStatus(status).length === 0" class="kanban-item">
                <div class="card">
                  <div class="card-content">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import draggable from 'vuedraggable';

export default {
  name: 'Leads',
  components: {
    draggable
  },
  data() {
    return {
      teamLeads: [],
      query: '',
      statusFilter: '',
      sortField: '',
      sortOrder: 'asc',
      team: {
        members: [],
        created_by: {}
      },
      statuses: ['new', 'contacted', 'scheduled', 'closed'],
      statusLabels: {
        new: 'New',
        contacted: 'Contacted',
        scheduled: 'Scheduled',
        closed: 'Closed'
      }
    };
  },
  mounted() {
    this.getTeamLeads();
    this.getTeam();
  },
  computed: {
    filteredTeamLeadsByStatus() {
      return (status) => {
        return this.teamLeads.filter(lead => lead.status === status);
      };
    }
  },
  methods: {
    searchLeads() {
      this.getTeamLeads();
    },
    async getTeam() {
      this.$store.commit('setIsLoading', true);
      await axios.get('/api/v1/teams/get_my_team/')
        .then(response => {
          this.team = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      this.$store.commit('setIsLoading', false);
    },
    async getTeamLeads() {
      this.$store.commit('setIsLoading', true);
      let queryString = `/api/v1/leads/all_leads/?search=${this.query}&status=${this.statusFilter}&ordering=${this.sortOrder === 'asc' ? '' : '-'}${this.sortField}`;
      await axios.get(queryString)
        .then(response => {
          this.teamLeads = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      this.$store.commit('setIsLoading', false);
    },
    async onEnd(event) {

      if (!event.from.dataset.id || !event.to.dataset.id) {
        console.error('Dataset ID missing on event.from or event.to');
        return;
      }

      const movedElementId = parseInt(event.item.dataset.id);
      let movedElement = this.teamLeads.find(lead => lead.id === movedElementId);

      const newStatus = event.to.dataset.id;
      if (movedElement && movedElement.status !== newStatus) {
        try {
          movedElement.status = newStatus;
          await axios.patch(`/api/v1/leads/${movedElement.id}/`, { "status": movedElement.status });
          movedElement.status = newStatus;
          this.getMyLeads();
          this.getTeamLeads();
        } catch (error) {
          console.error('Error updating lead status:', error);
        }
      } else {
        console.error('Moved element not found or status unchanged');
      }
    }
  }
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.animated-button {
    transition: transform 0.3s, background-color 0.3s;
}

.animated-button:hover {
    transform: scale(1.05);
    background-color: #006bb3;
}

.kanban-board {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    background-color: #f4f5f7;
    border-radius: 8px;
    flex-direction: column; /* Added for mobile responsiveness */
}

.kanban-column {
    flex: 1;
    margin-bottom: 10px; /* Changed from margin-right for mobile responsiveness */
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling */
}

.kanban-list {
    min-height: 200px;
}

.kanban-item {
    margin-bottom: 10px;
}

.status-label {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background: white; /* Or any other background color to match your design */
  z-index: 10; /* Ensure it stays on top */
  padding: 10 0; /* Adjust padding as needed */
  margin: 0;
}

.card {
    animation: slideInUp 0.5s;
}

.card-header {
    background-color: #e0e0e0;
}

.card-header-title {
    font-size: 1rem;
    color: #333;
}

.card-content {
    padding: 10px;
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
    .kanban-board {
        flex-direction: row; /* Flex row direction for larger screens */
    }

    .kanban-column {
        margin-right: 10px; /* Restore margin-right for larger screens */
        margin-bottom: 0; /* Remove margin-bottom for larger screens */
        max-height: 600px; /* Fixed height for scrollable columns */
    }
}

@media (max-width: 767px) {
    .kanban-column {
        margin-bottom: 20px;
        max-height: 250px; /* Fixed height for scrollable columns */
    }

    .kanban-item {
        margin-bottom: 20px;
    }

    .card-header-title {
        font-size: 0.9rem;
    }
    .form .column {
        margin-bottom: -10px !important; /* Ensure all form columns have reduced margin */
    }
    .form .field {
        margin-bottom: -10px !important; /* Ensure all form fields have reduced margin */
    }
}
</style>






